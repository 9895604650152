import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
export type OrgFormStateFragment = { __typename?: 'Org', name: string, deactivatedAt?: string | null, startedAt?: string | null, seatsExpected?: number | null, timezone: Types.Timezone };

export const OrgFormStateFragmentDoc = gql`
    fragment OrgFormState on Org {
  name
  deactivatedAt
  startedAt
  seatsExpected
  timezone
}
    `;