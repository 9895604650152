import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMyOrgMutationVariables = Types.Exact<{
  orgId: Types.Scalars['Int'];
}>;


export type UpdateMyOrgMutation = { __typename?: 'Mutation', updateMyOrg: { __typename?: 'BaseMutationResult', success: boolean } };

export type AvailableOrgFragment = { __typename?: 'Org', id: number, name: string, deactivatedAt?: string | null, brandDetails?: { __typename?: 'BrandDetails', id: string, logoUrls?: { __typename?: 'ImageUrls', id: string, thumb?: string | null, original: string } | null } | null };

export type OrgSwitcherQueryVariables = Types.Exact<{
  input: Types.AvailableOrgsInput;
}>;


export type OrgSwitcherQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', availableOrgs: { __typename?: 'Orgs', totalCount: number, objects: Array<{ __typename?: 'Org', id: number, name: string, deactivatedAt?: string | null, brandDetails?: { __typename?: 'BrandDetails', id: string, logoUrls?: { __typename?: 'ImageUrls', id: string, thumb?: string | null, original: string } | null } | null }> }, totalOrgCount: { __typename?: 'Orgs', totalCount: number } } | null };

export const AvailableOrgFragmentDoc = gql`
    fragment AvailableOrg on Org {
  id
  name
  deactivatedAt
  brandDetails {
    id
    logoUrls {
      id
      thumb
      original
    }
  }
}
    `;
export const UpdateMyOrgDocument = gql`
    mutation UpdateMyOrg($orgId: Int!) {
  updateMyOrg(orgId: $orgId) {
    success
  }
}
    `;
export type UpdateMyOrgMutationFn = Apollo.MutationFunction<UpdateMyOrgMutation, UpdateMyOrgMutationVariables>;

/**
 * __useUpdateMyOrgMutation__
 *
 * To run a mutation, you first call `useUpdateMyOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyOrgMutation, { data, loading, error }] = useUpdateMyOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useUpdateMyOrgMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyOrgMutation, UpdateMyOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyOrgMutation, UpdateMyOrgMutationVariables>(UpdateMyOrgDocument, options);
      }
export type UpdateMyOrgMutationHookResult = ReturnType<typeof useUpdateMyOrgMutation>;
export type UpdateMyOrgMutationResult = Apollo.MutationResult<UpdateMyOrgMutation>;
export type UpdateMyOrgMutationOptions = Apollo.BaseMutationOptions<UpdateMyOrgMutation, UpdateMyOrgMutationVariables>;
export const OrgSwitcherDocument = gql`
    query OrgSwitcher($input: AvailableOrgsInput!) {
  Me {
    availableOrgs(input: $input, pagination: {limit: 50}) {
      totalCount
      objects {
        ...AvailableOrg
      }
    }
    totalOrgCount: availableOrgs(input: {}, pagination: {limit: 1}) {
      totalCount
    }
  }
}
    ${AvailableOrgFragmentDoc}`;

/**
 * __useOrgSwitcherQuery__
 *
 * To run a query within a React component, call `useOrgSwitcherQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSwitcherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSwitcherQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgSwitcherQuery(baseOptions: Apollo.QueryHookOptions<OrgSwitcherQuery, OrgSwitcherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgSwitcherQuery, OrgSwitcherQueryVariables>(OrgSwitcherDocument, options);
      }
export function useOrgSwitcherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgSwitcherQuery, OrgSwitcherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgSwitcherQuery, OrgSwitcherQueryVariables>(OrgSwitcherDocument, options);
        }
export type OrgSwitcherQueryHookResult = ReturnType<typeof useOrgSwitcherQuery>;
export type OrgSwitcherLazyQueryHookResult = ReturnType<typeof useOrgSwitcherLazyQuery>;
export type OrgSwitcherQueryResult = Apollo.QueryResult<OrgSwitcherQuery, OrgSwitcherQueryVariables>;