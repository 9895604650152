import React from "react";
import useUser from "@src/hooks/useUser";
import { UserType } from "@src/types.generated";

export const useIsTrainee = (): { isTrainee: boolean; loading: boolean } => {
  const { user } = useUser();
  const isTrainee = React.useMemo(
    () => user?.userType === UserType.Employee,
    [user],
  );
  return React.useMemo(
    () => ({ isTrainee, loading: !user }),
    [user, isTrainee],
  );
};
