import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { PathFragmentDoc } from '../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePathMutationVariables = Types.Exact<{
  input: Types.PathInput;
}>;


export type CreatePathMutation = { __typename?: 'Mutation', createPath: { __typename?: 'PathMutationResult', success: boolean, path?: { __typename?: 'Path', id: number, libraryItem: { __typename?: 'LibraryItem', id: string, createdAt: string, lastEditedAt?: string | null, openedAt?: string | null, archivedAt?: string | null, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, createdBy?: { __typename?: 'Employee', id: number, name: string } | null, lastEditedBy?: { __typename?: 'Employee', id: number, name: string } | null }, draftVersion: { __typename?: 'PathVersion', id: string } } | null } };


export const CreatePathDocument = gql`
    mutation createPath($input: PathInput!) {
  createPath(input: $input) {
    success
    path {
      ...Path
    }
  }
}
    ${PathFragmentDoc}`;
export type CreatePathMutationFn = Apollo.MutationFunction<CreatePathMutation, CreatePathMutationVariables>;

/**
 * __useCreatePathMutation__
 *
 * To run a mutation, you first call `useCreatePathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPathMutation, { data, loading, error }] = useCreatePathMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePathMutation(baseOptions?: Apollo.MutationHookOptions<CreatePathMutation, CreatePathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePathMutation, CreatePathMutationVariables>(CreatePathDocument, options);
      }
export type CreatePathMutationHookResult = ReturnType<typeof useCreatePathMutation>;
export type CreatePathMutationResult = Apollo.MutationResult<CreatePathMutation>;
export type CreatePathMutationOptions = Apollo.BaseMutationOptions<CreatePathMutation, CreatePathMutationVariables>;