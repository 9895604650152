import useMouseDownOutside from "@hooks/useMouseDownOutside";
import React, { FC, useRef, useState, useEffect } from "react";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { css, StyleSheet, StyleDeclaration } from "aphrodite";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  title: string;
  onTitleSave: (title: string) => void;
  editStateEnabled: boolean;
  onLeaveEditState: () => void;
  titleInputStyleDeclaration?: StyleDeclaration;
  titleStyleDeclaration?: StyleDeclaration;
  children?: JSX.Element;
  editStateIconStyleDeclaration?: StyleDeclaration;
  leaveOnClickOutside?: boolean;
  maxLength?: number;
  showX?: boolean;
};

const EditableText: FC<Props> = ({
  title: initialTitle,
  onTitleSave,
  editStateEnabled,
  onLeaveEditState,
  titleStyleDeclaration,
  titleInputStyleDeclaration,
  children,
  editStateIconStyleDeclaration,
  leaveOnClickOutside = false,
  maxLength = undefined,
  showX = true,
}) => {
  const [title, setTitle] = useState(initialTitle);
  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle, setTitle]);
  const ref = useRef(null);
  const saveTitleEdit = (currentTitle: string) => {
    if (currentTitle) {
      onLeaveEditState();
      onTitleSave(currentTitle);
    } else {
      canceTitleEdit();
    }
  };
  const canceTitleEdit = () => {
    onLeaveEditState();
    setTitle(initialTitle);
  };
  useMouseDownOutside(ref, () => {
    if (leaveOnClickOutside) {
      onLeaveEditState();
      return;
    }
    if (editStateEnabled) {
      saveTitleEdit(title);
    }
  });
  return (
    <>
      {editStateEnabled && (
        <div ref={ref} className={css(styles.editRow)}>
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            className={css(
              titleInputStyleDeclaration,
              titleStyleDeclaration,
              styles.title,
              styles.titleInput,
            )}
            value={title}
            maxLength={maxLength}
            onFocus={(event) => event.target.select()}
            onChange={(event) => setTitle(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Escape") {
                canceTitleEdit();
                setTitle(initialTitle);
              } else if (event.key === "Enter") {
                saveTitleEdit(title);
              }
            }}
          />
          {showX && (
            <div className={css(styles.editStateIconsRow)}>
              <DeprecatedIcon
                type="close-circle"
                color={deprecatedTones.gray5}
                onClick={canceTitleEdit}
                svgStyleDeclaration={editStateIconStyleDeclaration}
              />
            </div>
          )}
        </div>
      )}
      {!editStateEnabled && (
        <>
          <div
            className={css(
              titleStyleDeclaration ? titleStyleDeclaration : styles.title,
            )}
          >
            {title}
          </div>
          {children}
        </>
      )}
    </>
  );
};

export default EditableText;

const styles = StyleSheet.create({
  title: {
    fontSize: "32px",
    fontWeight: 600,
    color: deprecatedColors.onPrimaryContainer,
  },
  titleInput: {
    borderRadius: "4px",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    boxSizing: "border-box",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    fontSize: "14px",
    ":focus": {
      boxShadow: `0 0 0 3px ${deprecatedTones.blue3}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
    ":hover:not(:focus):not(.error)": {
      border: `1px solid ${deprecatedTones.gray5}`,
    },
  },
  editRow: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  editStateIconsRow: {
    position: "absolute",
    right: "4px",
    display: "flex",
    alignItems: "center",
  },
});
