import gql from "graphql-tag";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import { MediaFragment } from "@hooks/useFileUpload";
import { useFileListUpload } from "@src/hooks/useFileListUpload";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MiscStyles from "@src/deprecatedDesignSystem/styles/MiscStyles";
import { css, StyleSheet } from "aphrodite";
import { FC, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import FileUploadImg from "./FileUploadImg";
import useAcceptedMediaTypes from "@src/components/libraryItemDetailPages/module/hooks/useAcceptedMediaTypes";

type Props = {
  generate: (mediaUrls: string[]) => void;
  disabled: boolean;
};

const UploadMedia: FC<Props> = ({ generate, disabled }) => {
  const { loading: mimeTypesLoading, acceptedMediaTypesObject } =
    useAcceptedMediaTypes();
  const inputRef = useRef<HTMLInputElement>(null);
  const openFilePicker = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };
  const setMediaList = useCallback(
    (media: MediaFragment[]) => generate(media.map((m) => m.url)),
    [generate],
  );
  const [uploadList, { loading: listLoading }] =
    useFileListUpload(setMediaList);
  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      uploadList(acceptedFiles);
    },
    [uploadList],
  );
  const { getRootProps, isDragActive } = useDropzone({
    useFsAccessApi: true,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: acceptedMediaTypesObject,
    onDrop,
  });
  return (
    <div
      className={css(
        styles.uploadFileSection,
        isDragActive && styles.draggingFileOverDropArea,
      )}
      {...getRootProps()}
    >
      <div className={css(styles.fileUploadImageContainer)}>
        {listLoading || mimeTypesLoading || disabled ? (
          <div className={css(styles.loadingContainer)}>
            <Spinner />
          </div>
        ) : (
          <div className={css(styles.fileUploadContainerInner)}>
            <FileUploadImg />
            <Text
              type="H3"
              fontWeight="SemiBold"
              styleDeclaration={styles.title}
            >
              Convert any PDF, Powerpoint, or image into a Module
            </Text>
            <Text
              type="P2"
              color={deprecatedTones.gray8}
              styleDeclaration={styles.descriptionStyles}
            >
              Our AI will create a Module outline from your files
            </Text>
            <div className={css(styles.fileUploadButtons)}>
              <Button
                variant="Primary"
                height="40px"
                leftIcon="upload-cloud"
                text="Upload files"
                onClick={openFilePicker}
                styleDeclaration={styles.fileUploadButtonStyles}
              />
              <input
                ref={inputRef}
                onChange={(e) => {
                  if (e.target.files) uploadList(e.target.files);
                }}
                type="file"
                multiple={true}
                style={{
                  display: "none",
                }}
                accept={Array.from(
                  Object.values(acceptedMediaTypesObject),
                ).join(",")}
              />
            </div>
            <Text
              type="P2"
              color={deprecatedTones.gray5}
              styleDeclaration={styles.descriptionStyles}
            >
              Or drag & drop files here
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  uploadFileSection: {
    width: "100%",
    height: 320,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `2px dashed ${deprecatedTones.gray5}`,
    borderColor: deprecatedTones.gray5Alpha,
    borderRadius: 8,
    backgroundColor: deprecatedColors.surface,
  },
  draggingFileOverDropArea: {
    ...MiscStyles.focus,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  fileUploadImageContainer: {
    height: 87,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  spinnerContainer: {
    marginBottom: 12,
  },
  fileUploadContainerInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  fileUploadButtons: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
  },
  descriptionStyles: {
    textAlign: "center",
    marginTop: 15,
  },
  title: {
    textAlign: "center",
  },
  fileUploadButtonStyles: {
    marginTop: 15,
    marginRight: 8,
  },
});

gql`
  query AIUploadMediaGetSupportedImagesMIMETypes {
    GetSupportedImagesMIMETypes {
      ext
      mimeType
    }
  }
`;

export default UploadMedia;
