import React from "react";
import { SidebarGroup, SidebarGroupContent } from "@src/ui/sidebar";
import AdminLeftNavNewButton from "@src/components/layout/left-nav/admin/AdminLeftNavNewButton";
import LeftNavOrgSwitcher from "@src/components/layout/left-nav/LeftNavOrgSwitcher";

const AdminLeftNavOrgAndNewButtons: React.FC = () => {
  return (
    <SidebarGroup>
      <SidebarGroupContent className="flex flex-col items-center justify-between gap-3">
        <LeftNavOrgSwitcher />
        <AdminLeftNavNewButton />
      </SidebarGroupContent>
    </SidebarGroup>
  );
};

export default AdminLeftNavOrgAndNewButtons;
