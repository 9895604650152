import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeeFeatureFlagsMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeFeatureFlagsInput;
}>;


export type UpdateEmployeeFeatureFlagsMutation = { __typename?: 'Mutation', updateEmployeeFeatureFlags: { __typename?: 'BaseMutationResult', success: boolean } };


export const UpdateEmployeeFeatureFlagsDocument = gql`
    mutation UpdateEmployeeFeatureFlags($input: UpdateEmployeeFeatureFlagsInput!) {
  updateEmployeeFeatureFlags(input: $input) {
    success
  }
}
    `;
export type UpdateEmployeeFeatureFlagsMutationFn = Apollo.MutationFunction<UpdateEmployeeFeatureFlagsMutation, UpdateEmployeeFeatureFlagsMutationVariables>;

/**
 * __useUpdateEmployeeFeatureFlagsMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeFeatureFlagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeFeatureFlagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeFeatureFlagsMutation, { data, loading, error }] = useUpdateEmployeeFeatureFlagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeFeatureFlagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeFeatureFlagsMutation, UpdateEmployeeFeatureFlagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeFeatureFlagsMutation, UpdateEmployeeFeatureFlagsMutationVariables>(UpdateEmployeeFeatureFlagsDocument, options);
      }
export type UpdateEmployeeFeatureFlagsMutationHookResult = ReturnType<typeof useUpdateEmployeeFeatureFlagsMutation>;
export type UpdateEmployeeFeatureFlagsMutationResult = Apollo.MutationResult<UpdateEmployeeFeatureFlagsMutation>;
export type UpdateEmployeeFeatureFlagsMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeFeatureFlagsMutation, UpdateEmployeeFeatureFlagsMutationVariables>;