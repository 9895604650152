import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { Card } from "@src/ui/card";
import Text from "@src/ui/text";
import { Button, ButtonProps } from "@src/ui/button";
import { cn } from "@src/ui/lib/utils";

const TooltipMenu = TooltipPrimitive.Root;

const TooltipMenuTrigger = TooltipPrimitive.Trigger;

const TooltipMenuContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    className={cn(
      "z-50 bg-transparent shadow-md animate-in max-w-64 fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 rounded-2xl",
      className,
    )}
    {...props}
  >
    <Card className="flex w-[180px] flex-col gap-1 p-2">{props.children}</Card>
  </TooltipPrimitive.Content>
));
TooltipMenuContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipMenuItem = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <Button
      ref={ref}
      variant="ghost"
      className={cn("h-7 justify-start px-2 hover:bg-gray-1", className)}
      {...props}
    />
  ),
);
TooltipMenuItem.displayName = "TooltipMenuItem";

const TooltipMenuHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div ref={ref} className="mt-0.5 mb-1 px-2" {...props}>
    <Text type="P3" fontWeight="Medium" className="text-muted-foreground">
      {children}
    </Text>
  </div>
));
TooltipMenuHeader.displayName = "TooltipMenuHeader";

export {
  TooltipMenu,
  TooltipMenuTrigger,
  TooltipMenuContent,
  TooltipMenuItem,
  TooltipMenuHeader,
};
