import useUser from "@hooks/useUser";
import useLanguage from "@src/hooks/useLanguage";
import { UserType } from "@src/types.generated";
import { useTranslation } from "@src/utils/translationSets";
import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@src/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { useRouter } from "next/router";
import { Button } from "@src/ui/button";
import { getFirstNameAndLastInitial } from "@src/components/layout/top-nav/utils";
import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";

const MY_PROFILE_ENGLISH = "My Profile";
const LOGOUT_ENGLISH = "Logout";

export const SettingsButton: React.FC = () => {
  const router = useRouter();
  const language = useLanguage();
  const myProfileTranslation = useTranslation(
    MY_PROFILE_ENGLISH,
    language || "en",
  );
  const logoutTranslation = useTranslation(LOGOUT_ENGLISH, language || "en");
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const isTrainee = !user || user.userType === UserType.Employee;
  const firstNameAndLastInitial = getFirstNameAndLastInitial(user?.name || "");
  if (!user) return null;
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <div>
          <Button
            variant="outline"
            className="flex items-center justify-between px-3"
          >
            {firstNameAndLastInitial}
            <ChevronDownIcon className="ml-2" />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-40" align="end">
        {!isTrainee ? (
          <>
            <DropdownMenuItem
              onClick={() => {
                router.push({
                  pathname: "/users/[id]",
                  query: { id: user.id.toString() },
                });
                setOpen(false);
              }}
            >
              {myProfileTranslation.text || MY_PROFILE_ENGLISH}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        ) : null}
        <DropdownMenuItem
          onClick={() => {
            router.push({
              pathname: "/logout",
            });
            setOpen(false);
          }}
        >
          {logoutTranslation.text || LOGOUT_ENGLISH}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SettingsButton;
