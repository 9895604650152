import * as React from "react";
import { IconProps } from "../types";

const IdBadgeOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M6.269 8.788C6.96985 8.788 7.538 8.21985 7.538 7.519C7.538 6.81815 6.96985 6.25 6.269 6.25C5.56815 6.25 5 6.81815 5 7.519C5 8.21985 5.56815 8.788 6.269 8.788Z"
          fill={color}
        />
        <path
          d="M8.309 11.685C8.677 11.569 8.871 11.162 8.715 10.809C8.3 9.871 7.362 9.215 6.27 9.215C5.178 9.215 4.24 9.87 3.824 10.809C3.668 11.162 3.861 11.569 4.23 11.685C4.755 11.85 5.449 12 6.27 12C7.091 12 7.785 11.851 8.31 11.685H8.309Z"
          fill={color}
        />
        <path
          d="M13 3.75H14.25C15.355 3.75 16.25 4.645 16.25 5.75V12.25C16.25 13.355 15.355 14.25 14.25 14.25H3.75C2.645 14.25 1.75 13.355 1.75 12.25V5.75C1.75 4.645 2.645 3.75 3.75 3.75H5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 0.75C9.69 0.75 10.25 1.31 10.25 2V4.25H7.75V2C7.75 1.31 8.31 0.75 9 0.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 7.75H13.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 10.75H13.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default IdBadgeOutlineIcon;
