import AdminLeftNav from "@src/components/layout/left-nav/admin/AdminLeftNav";
import LoadingLeftNav from "@src/components/layout/left-nav/LoadingLeftNav";
import TraineeLeftNav from "@src/components/layout/left-nav/TraineeLeftNav";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { useIsTrainee } from "@src/hooks/useIsTrainee";

const LeftNav: React.FC = () => {
  const { isTrainee, loading: isTraineeLoading } = useIsTrainee();
  const { loading: userActionPermissionsLoading } = useActionPermissions();
  if (isTraineeLoading || userActionPermissionsLoading)
    return <LoadingLeftNav />;
  if (isTrainee) return <TraineeLeftNav />;
  return <AdminLeftNav />;
};

export default LeftNav;
