import { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import UploadMedia from "@components/modals/ai/UploadMedia";

type Props = {
  loading: boolean;
  generateModuleFromMedia: (mediaUrls: string[]) => void;
};

const AIGenerateUploadFiles: FC<Props> = ({
  loading,
  generateModuleFromMedia,
}) => {
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      spaceBetweenItems={12}
    >
      <UploadMedia disabled={loading} generate={generateModuleFromMedia} />
    </AutoLayout>
  );
};

export default AIGenerateUploadFiles;
