import { UserType } from "@src/types.generated";
import React, { FC } from "react";
import UserTypeDescription from "@src/components/users/modals/inviteOrEditUserModal/UserTypeDescription";
import SegmentedControl from "@src/deprecatedDesignSystem/components/SegmentedControl";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

type Props = {
  userType: UserType;
  setUserType: (role: UserType) => void;
  disableTraineeOption?: boolean;
  showDescription: boolean;
};
const UserTypeSelection: FC<Props> = ({
  userType,
  setUserType,
  disableTraineeOption,
  showDescription,
}) => {
  let choices;
  if (disableTraineeOption) {
    choices = [
      {
        value: UserType.Manager,
        label: "Manager",
        dataTestId: "ManagerSelection",
      },
      { value: UserType.Admin, label: "Admin", dataTestId: "AdminSelection" },
    ];
  } else {
    choices = [
      {
        value: UserType.Employee,
        label: "Trainee",
        dataTestId: "TraineeSelection",
      },
      {
        value: UserType.Manager,
        label: "Manager",
        dataTestId: "ManagerSelection",
      },
      { value: UserType.Admin, label: "Admin", dataTestId: "AdminSelection" },
    ];
  }
  return (
    <AutoLayout direction="vertical" flex={1} alignSelf="stretch">
      <SegmentedControl
        selectedOption={userType}
        onOptionSelected={(value) => setUserType(value as UserType)}
        options={choices}
      />
      {showDescription && <UserTypeDescription userType={userType} />}
    </AutoLayout>
  );
};

export default UserTypeSelection;
