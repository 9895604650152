import React from "react";
import { Sidebar, SidebarContent } from "@src/ui/sidebar";
import AdminLeftNavOrgAndNewButtons from "@src/components/layout/left-nav/admin/AdminLeftNavOrgAndNewButtons";
import AdminLeftNavMainMenu from "@src/components/layout/left-nav/admin/AdminLeftNavMainMenu";
import AdminLeftNavLibraryMenu from "@src/components/layout/left-nav/admin/AdminLeftNavLibraryMenu";
import AdminLeftNavPeopleMenu from "@src/components/layout/left-nav/admin/AdminLeftNavPeopleMenu";
import AdminLeftNavWorkflowsMenu from "@src/components/layout/left-nav/admin/AdminLeftNavWorkflowsMenu";

const AdminLeftNav: React.FC = () => {
  return (
    <Sidebar collapsible="icon">
      <SidebarContent>
        <AdminLeftNavOrgAndNewButtons />
        <AdminLeftNavMainMenu />
        <AdminLeftNavLibraryMenu />
        <AdminLeftNavPeopleMenu />
        <AdminLeftNavWorkflowsMenu />
      </SidebarContent>
      <div className="h-5"></div>
    </Sidebar>
  );
};

export default AdminLeftNav;
