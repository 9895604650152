import * as React from "react";
import { IconProps } from "../types";

const StudentIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M9.70101 0.838997L15.23 3.272C15.429 3.36 15.429 3.642 15.23 3.73L9.70101 6.163C9.57301 6.219 9.42701 6.219 9.29801 6.163L3.77001 3.729C3.57101 3.641 3.57101 3.359 3.77001 3.271L9.29901 0.838997C9.42701 0.782997 9.57201 0.782997 9.70101 0.838997Z"
          fill={color}
          opacity="0.3"
        />
        <path
          d="M15.379 3.5C15.379 3.5 15.062 6.13 16 7.989"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.453 16C4.751 14.042 6.975 12.75 9.5 12.75C12.025 12.75 14.249 14.041 15.547 16"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.70101 0.838997L15.23 3.272C15.429 3.36 15.429 3.642 15.23 3.73L9.70101 6.163C9.57301 6.219 9.42701 6.219 9.29801 6.163L3.77001 3.729C3.57101 3.641 3.57101 3.359 3.77001 3.271L9.29901 0.838997C9.42701 0.782997 9.57201 0.782997 9.70101 0.838997Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.181 4.78999C5.915 5.30499 5.75 5.88099 5.75 6.49999C5.75 8.57099 7.429 10.25 9.5 10.25C11.571 10.25 13.25 8.57099 13.25 6.49999C13.25 5.87999 13.085 5.30499 12.819 4.78999"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default StudentIcon;
