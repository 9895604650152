import React, { FC } from "react";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { useState } from "react";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import Text from "@ui/text";

type Props = {
  text?: string;
  onClick: () => void;
  disabled?: boolean;
  size?: number;
};

const AddInlineButton: FC<Props> = ({ onClick, disabled, text, size }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <button
      className={css(styles.button)}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <DeprecatedIcon
        type="plus-circle"
        color={
          hovering
            ? deprecatedColors.onPrimaryContainer
            : deprecatedColors.primary
        }
        iconSize={size}
        style={{ borderRadius: "100%" }}
      />
      {text && (
        <Text
          type="P2"
          fontWeight="Medium"
          styleDeclaration={styles.text}
          color={
            hovering
              ? deprecatedColors.onPrimaryContainer
              : deprecatedColors.primary
          }
        >
          {text}
        </Text>
      )}
    </button>
  );
};

export default AddInlineButton;

const styles = StyleSheet.create({
  button: {
    background: "none",
    border: "none",
    outline: "none",
    fontSize: "14px",
    color: deprecatedColors.primary,
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
    ":hover": {
      color: deprecatedColors.onPrimaryContainer,
    },
    ":disabled": {
      opacity: 0.5,
    },
  },
  text: {
    marginLeft: "4px",
    whiteSpace: "nowrap",
    marginRight: "8px",
  },
});
