import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingBannerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BillingBannerQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, paysManually: boolean } | null } } | null, BillingAccounts: Array<{ __typename?: 'BillingAccount', id: string, paymentMethod?: { __typename?: 'PaymentMethod', id: string } | null }> };


export const BillingBannerDocument = gql`
    query BillingBanner {
  Me {
    user {
      id
      org {
        id
        paysManually
      }
    }
  }
  BillingAccounts(input: {}) {
    id
    paymentMethod {
      id
    }
  }
}
    `;

/**
 * __useBillingBannerQuery__
 *
 * To run a query within a React component, call `useBillingBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingBannerQuery(baseOptions?: Apollo.QueryHookOptions<BillingBannerQuery, BillingBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingBannerQuery, BillingBannerQueryVariables>(BillingBannerDocument, options);
      }
export function useBillingBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingBannerQuery, BillingBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingBannerQuery, BillingBannerQueryVariables>(BillingBannerDocument, options);
        }
export type BillingBannerQueryHookResult = ReturnType<typeof useBillingBannerQuery>;
export type BillingBannerLazyQueryHookResult = ReturnType<typeof useBillingBannerLazyQuery>;
export type BillingBannerQueryResult = Apollo.QueryResult<BillingBannerQuery, BillingBannerQueryVariables>;