import NotificationList from "@src/components/layout/top-nav/NotificationList";
import NotificationDot from "@src/deprecatedDesignSystem/components/NotificationDot";
import gql from "graphql-tag";
import { debounce } from "lodash";
import React from "react";
import {
  useGetNotificationsQuery,
  useMarkNotificationsReadMutation,
} from "./NotificationBell.generated";
import { Button } from "@src/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "@src/ui/dropdown-menu";
import BellOutlineIcon from "@src/ui/icons/18px/bell-outline";

export const NotificationBell: React.FC = () => {
  const PAGINATION_LIMIT = 15;
  const {
    data: newData,
    previousData,
    loading,
    fetchMore,
  } = useGetNotificationsQuery({
    variables: {
      pagination: {
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });
  const data = newData || previousData;
  const totalNotifications = React.useMemo(() => {
    return data?.Notifications.totalCount || 0;
  }, [data?.Notifications.totalCount]);
  const notifications = React.useMemo(() => {
    return data?.Notifications.objects || [];
  }, [data?.Notifications.objects]);
  const hasMore = React.useMemo(() => {
    return notifications.length < totalNotifications;
  }, [notifications.length, totalNotifications]);
  const totalUnreadNotifications = React.useMemo(() => {
    return data?.UnreadCount || 0;
  }, [data?.UnreadCount]);
  const [readNotificationIds, setReadNotificationIds] = React.useState<
    Set<string>
  >(new Set());
  const [markNotificationsRead] = useMarkNotificationsReadMutation({
    refetchQueries: ["GetNotifications"],
    variables: {
      ids: Array.from(readNotificationIds),
    },
    onCompleted: () => {
      setReadNotificationIds(new Set());
    },
  });
  const onNotificationRead = (id: string) => {
    setReadNotificationIds((prev) => new Set(prev).add(id));
  };
  const debouncedFetchMore = debounce(
    () =>
      fetchMore({
        variables: {
          pagination: {
            limit: PAGINATION_LIMIT,
            offset: notifications.length,
          },
        },
      }),
    500,
  );
  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (!open) {
          markNotificationsRead();
        }
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="outline" className="relative">
          <BellOutlineIcon />
          {totalUnreadNotifications >= 1 && (
            <NotificationDot
              size={24}
              count={Math.min(totalUnreadNotifications, 99)}
              className="absolute right-[-10px] top-[-10px]"
            />
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="size-[400px]">
        <NotificationList
          notifications={notifications}
          loading={loading}
          hasMore={hasMore}
          fetchMoreNotifications={() => {
            if (hasMore) {
              debouncedFetchMore();
            }
          }}
          onNotificationRead={onNotificationRead}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NotificationBell;

gql`
  query Notifications {
    AdminInProductNotifications {
      ...Notification
    }
  }
  query GetNotifications($pagination: PaginationInput) {
    Notifications: InProductNotifications(input: {}, pagination: $pagination) {
      totalCount
      objects {
        ...Notification
      }
    }
    UnreadCount: InProductNotificationUnreadCount
  }
  mutation MarkNotificationsRead($ids: [String!]!) {
    markInProductNotificationsAsRead(inProductNotificationIds: $ids) {
      success
    }
  }
`;
