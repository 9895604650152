import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiUploadMediaGetSupportedImagesMimeTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AiUploadMediaGetSupportedImagesMimeTypesQuery = { __typename?: 'Query', GetSupportedImagesMIMETypes: Array<{ __typename?: 'MIMEType', ext: string, mimeType: string }> };


export const AiUploadMediaGetSupportedImagesMimeTypesDocument = gql`
    query AIUploadMediaGetSupportedImagesMIMETypes {
  GetSupportedImagesMIMETypes {
    ext
    mimeType
  }
}
    `;

/**
 * __useAiUploadMediaGetSupportedImagesMimeTypesQuery__
 *
 * To run a query within a React component, call `useAiUploadMediaGetSupportedImagesMimeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiUploadMediaGetSupportedImagesMimeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiUploadMediaGetSupportedImagesMimeTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAiUploadMediaGetSupportedImagesMimeTypesQuery(baseOptions?: Apollo.QueryHookOptions<AiUploadMediaGetSupportedImagesMimeTypesQuery, AiUploadMediaGetSupportedImagesMimeTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiUploadMediaGetSupportedImagesMimeTypesQuery, AiUploadMediaGetSupportedImagesMimeTypesQueryVariables>(AiUploadMediaGetSupportedImagesMimeTypesDocument, options);
      }
export function useAiUploadMediaGetSupportedImagesMimeTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiUploadMediaGetSupportedImagesMimeTypesQuery, AiUploadMediaGetSupportedImagesMimeTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiUploadMediaGetSupportedImagesMimeTypesQuery, AiUploadMediaGetSupportedImagesMimeTypesQueryVariables>(AiUploadMediaGetSupportedImagesMimeTypesDocument, options);
        }
export type AiUploadMediaGetSupportedImagesMimeTypesQueryHookResult = ReturnType<typeof useAiUploadMediaGetSupportedImagesMimeTypesQuery>;
export type AiUploadMediaGetSupportedImagesMimeTypesLazyQueryHookResult = ReturnType<typeof useAiUploadMediaGetSupportedImagesMimeTypesLazyQuery>;
export type AiUploadMediaGetSupportedImagesMimeTypesQueryResult = Apollo.QueryResult<AiUploadMediaGetSupportedImagesMimeTypesQuery, AiUploadMediaGetSupportedImagesMimeTypesQueryVariables>;