import { useUpdateStaffActionPermissionsMutation } from "@src/components/layout/top-nav/SetActionPermissions.generated";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import ToggleButton from "@src/deprecatedDesignSystem/components/ToggleButton";
import useActionPermissions from "@hooks/useActionPermissions";
import useUser from "@hooks/useUser";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { GraphqlOperations, UserType } from "@src/types.generated";
import {
  USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION,
  UserActionPermissionsKey,
} from "@utils/actionPermissions";
import { StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import { startCase, toLower } from "lodash";
import { FC, useCallback, useMemo, useState } from "react";
import { useUpdateUserMutation } from "@src/components/users/user-details/UserDetails.generated";

const SetActionPermissions: FC = () => {
  const { isStaff } = useUser();
  const { userActionPermissions, loading } = useActionPermissions();
  const [updateStaffPermissions] = useUpdateStaffActionPermissionsMutation({
    refetchQueries: [GraphqlOperations.Query.UserActionPermissions],
  });

  const updatePermission = useCallback(
    (field: UserActionPermissionsKey, currentValue: boolean) => {
      updateStaffPermissions({
        variables: {
          input: {
            [field]: {
              value: !currentValue,
            },
          },
        },
      });
    },
    [updateStaffPermissions],
  );
  const permissionToggleRows = useMemo(() => {
    if (!userActionPermissions) return null;
    return Object.entries(userActionPermissions).map(
      ([_permissionKey, selected]) => {
        const permissionKey = _permissionKey as UserActionPermissionsKey;
        return (
          <PermissionToggleRow
            key={permissionKey}
            title={
              USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION[permissionKey].name
            }
            selected={selected}
            onUpdate={() => updatePermission(permissionKey, selected)}
          />
        );
      },
    );
  }, [userActionPermissions, updatePermission]);
  const [togglesExpanded, setTogglesExpanded] = useState(false);
  if (!isStaff) {
    return null;
  }
  return (
    <AutoLayout direction="vertical">
      <AutoLayout direction="vertical" marginTop={12}>
        <Text type="P1" fontWeight="SemiBold">
          Update User Type
        </Text>
        <AutoLayout direction="vertical" paddingTop={12}>
          <AutoLayout direction="horizontal" spaceBetweenItems={6}>
            <UpdateUserTypeButton userType={UserType.Employee} />
            <UpdateUserTypeButton userType={UserType.Manager} />
            <UpdateUserTypeButton userType={UserType.Admin} />
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
      <AutoLayout
        onClick={() => setTogglesExpanded(!togglesExpanded)}
        alignmentVertical="center"
        marginTop={12}
        className="cursor-pointer"
      >
        <Text type="P1" fontWeight="SemiBold">
          Override permissions
        </Text>
        <DeprecatedIcon
          type={togglesExpanded ? "chevron-down" : "chevron-right"}
          iconSize={32}
          containerSize={32}
        />
      </AutoLayout>
      {togglesExpanded && (
        <>
          {loading && userActionPermissions == null ? (
            <Spinner />
          ) : (
            <AutoLayout direction="vertical" spaceBetweenItems={6}>
              {permissionToggleRows}
            </AutoLayout>
          )}
        </>
      )}
    </AutoLayout>
  );
};

type PermissionToggleRowProps = {
  title: string;
  selected: boolean;
  onUpdate: () => void;
};

const PermissionToggleRow: FC<PermissionToggleRowProps> = ({
  title,
  selected,
  onUpdate,
}) => (
  <AutoLayout direction="horizontal">
    <Text styleDeclaration={styles.text} type="P2" multiline>
      {title}
    </Text>
    <ToggleButton selected={selected} onClick={onUpdate} />
  </AutoLayout>
);

type UpdateUserTypeButtonProps = {
  userType: UserType;
};

const UpdateUserTypeButton: FC<UpdateUserTypeButtonProps> = ({ userType }) => {
  const { user } = useUser();
  const [updateUserType, { loading: saveUserTypeLoading }] =
    useUpdateUserMutation({
      refetchQueries: [
        GraphqlOperations.Query.UserActionPermissions,
        GraphqlOperations.Query.Init,
      ],
    });
  return (
    <Button
      text={startCase(toLower(userType))}
      variant={user?.userType === userType ? "Primary" : "No Outline"}
      loading={saveUserTypeLoading}
      onClick={() => {
        if (!user?.id || !user?.userType || user.userType === userType) {
          return;
        }
        updateUserType({
          variables: {
            id: user.id,
            input: {
              userType: { value: userType },
            },
          },
        });
      }}
    />
  );
};

export default SetActionPermissions;

gql`
  mutation updateStaffActionPermissions($input: UserActionPermissionsInput!) {
    updateUserActionPermissions(input: $input) {
      success
    }
  }
`;

const styles = StyleSheet.create({
  text: {
    width: 200,
  },
});
