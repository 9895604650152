import { gql } from "@apollo/client";
import { OrgFormStateFragment } from "@components/modals/orgManagement/useOrgFormState.generated";
import { Timezone } from "@src/types.generated";
import { useState } from "react";

export type OrgFormState = {
  name: string;
  setName: (name: string) => void;
  deactivatedAt: Date | undefined;
  setDeactivatedAt: (deactivatedAt: Date | undefined) => void;
  startedAt: Date | undefined;
  setStartedAt: (startedAt: Date | undefined) => void;
  seatsExpected: number;
  setSeatsExpected: (seatsExpected: number) => void;
  timezone: Timezone;
  setTimezone: (timezone: Timezone) => void;
  isTrial: boolean;
  setIsTrial: (isTrial: boolean) => void;
};

export const useOrgFormState = (org?: OrgFormStateFragment): OrgFormState => {
  const [name, setName] = useState(org?.name || "");
  const [deactivatedAt, setDeactivatedAt] = useState<Date | undefined>(
    org?.deactivatedAt ? new Date(org.deactivatedAt) : undefined,
  );
  const [startedAt, setStartedAt] = useState<Date | undefined>(
    org?.startedAt ? new Date(org.startedAt) : undefined,
  );
  const [seatsExpected, setSeatsExpected] = useState(org?.seatsExpected || 0);
  const [timezone, setTimezone] = useState<Timezone>(
    org?.timezone || Timezone.UsEastern,
  );
  const [isTrial, setIsTrial] = useState(false);
  return {
    name,
    setName,
    deactivatedAt,
    setDeactivatedAt,
    startedAt,
    setStartedAt,
    seatsExpected,
    setSeatsExpected,
    timezone,
    setTimezone,
    isTrial,
    setIsTrial,
  };
};

gql`
  fragment OrgFormState on Org {
    name
    deactivatedAt
    startedAt
    seatsExpected
    timezone
  }
`;
