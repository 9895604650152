import Modal from "@components/modals/Modal";
import NoPermissionModal from "@components/modals/NoPermissionModal";
import InviteQRCodePicker from "@components/modals/invitePeople/InviteQRCodePicker";
import useActionPermissions from "@hooks/useActionPermissions";
import { FC } from "react";

type Props = {
  initialLocationId?: number | null;
};

const InviteQRCodeModal: FC<Props> = ({ initialLocationId = null }) => {
  const { userActionPermissions } = useActionPermissions();
  if (!userActionPermissions?.inviteOthers) {
    return <NoPermissionModal permissionType="invite others" />;
  }
  return (
    <Modal title="Invite Trainees" style={{ width: 375, height: 600 }}>
      <InviteQRCodePicker initialLocationId={initialLocationId} />
    </Modal>
  );
};

export default InviteQRCodeModal;
