import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import { cn } from "@src/ui/lib/utils";

type Props = {
  children: React.ReactNode;
  hasPermission?: boolean;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  tooltipAlign?: "start" | "center" | "end";
  className?: string;
  disabledOpacity?: number;
};

const NoPermissionsTooltip: React.FC<Props> = ({
  children,
  hasPermission = false,
  tooltipSide = "right",
  tooltipAlign = "center",
  className,
  disabledOpacity = 0.6,
}) => {
  if (hasPermission) {
    return <>{children}</>;
  }

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger
          asChild
          className={cn("cursor-not-allowed", className)}
          style={{ opacity: disabledOpacity }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {children}
        </TooltipTrigger>
        <TooltipContent
          side={tooltipSide}
          align={tooltipAlign}
          className="bg-popover text-popover-foreground"
        >
          You do not have permission
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default NoPermissionsTooltip;
