import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@src/ui/collapsible";
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
  useSidebar,
} from "@src/ui/sidebar";
import GearOutlineIcon from "@src/ui/icons/18px/gear-outline";
import {
  TooltipMenu,
  TooltipMenuContent,
  TooltipMenuHeader,
  TooltipMenuItem,
  TooltipMenuTrigger,
} from "@src/ui/tooltip-menu";
import { useBillingInitialized } from "@src/hooks/billing/useBillingInitialized";
import useActionPermissions from "@src/hooks/useActionPermissions";

const AdminLeftNavSettingsMenuButton: React.FC = () => {
  const { pathname } = useRouter();
  const { state } = useSidebar();
  const { userActionPermissions } = useActionPermissions();
  const billingInitialized = useBillingInitialized();
  const SettingsMenuButton = React.useMemo(
    () => (
      <SidebarMenuButton asChild>
        <Link href="/settings">
          <GearOutlineIcon />
          Settings
        </Link>
      </SidebarMenuButton>
    ),
    [],
  );
  return (
    <SidebarMenuItem className="relative">
      {state === "collapsed" ? (
        <TooltipMenu>
          <TooltipMenuTrigger asChild>{SettingsMenuButton}</TooltipMenuTrigger>
          <TooltipMenuContent side="right" align="center">
            <TooltipMenuHeader>Settings</TooltipMenuHeader>
            {userActionPermissions?.changeOrgSettings ? (
              <>
                <TooltipMenuItem>
                  <Link href="/settings">Brand</Link>
                </TooltipMenuItem>
                <TooltipMenuItem>
                  <Link href="/settings/ai-assist">AI Assist</Link>
                </TooltipMenuItem>
                <TooltipMenuItem>
                  <Link href="/settings/compliance">Wage & Hour</Link>
                </TooltipMenuItem>
                <TooltipMenuItem>
                  <Link href="/settings/language">Languages</Link>
                </TooltipMenuItem>
              </>
            ) : null}
            <TooltipMenuItem>
              <Link href="/settings/email">Email</Link>
            </TooltipMenuItem>
            {billingInitialized && userActionPermissions?.manageBilling ? (
              <TooltipMenuItem>
                <Link href="/settings/billing">Billing</Link>
              </TooltipMenuItem>
            ) : null}
            {userActionPermissions?.createContent ? (
              <TooltipMenuItem>
                <Link href="/settings/archive">Archive</Link>
              </TooltipMenuItem>
            ) : null}
            {userActionPermissions?.manageOrgIntegrations ? (
              <TooltipMenuItem>
                <Link href="/settings/hris">HRIS</Link>
              </TooltipMenuItem>
            ) : null}
          </TooltipMenuContent>
        </TooltipMenu>
      ) : (
        <Collapsible
          className="group/collapsible group/settings"
          open={pathname.startsWith("/settings")}
        >
          <CollapsibleTrigger asChild>{SettingsMenuButton}</CollapsibleTrigger>
          <CollapsibleContent>
            {userActionPermissions?.changeOrgSettings ? (
              <>
                <SidebarMenuSub>
                  <SidebarMenuSubItem
                    asChild
                    isActive={pathname === "/settings/brand"}
                  >
                    <Link href="/settings/brand">Brand</Link>
                  </SidebarMenuSubItem>
                </SidebarMenuSub>
                <SidebarMenuSub>
                  <SidebarMenuSubItem
                    asChild
                    isActive={pathname === "/settings/ai-assist"}
                  >
                    <Link href="/settings/ai-assist">AI Assist</Link>
                  </SidebarMenuSubItem>
                </SidebarMenuSub>
                <SidebarMenuSub>
                  <SidebarMenuSubItem
                    asChild
                    isActive={pathname === "/settings/compliance"}
                  >
                    <Link href="/settings/compliance">Wage & Hour</Link>
                  </SidebarMenuSubItem>
                </SidebarMenuSub>
                <SidebarMenuSub>
                  <SidebarMenuSubItem
                    asChild
                    isActive={pathname === "/settings/language"}
                  >
                    <Link href="/settings/language">Languages</Link>
                  </SidebarMenuSubItem>
                </SidebarMenuSub>
              </>
            ) : null}
            <SidebarMenuSub>
              <SidebarMenuSubItem
                asChild
                isActive={pathname === "/settings/email"}
              >
                <Link href="/settings/email">Email</Link>
              </SidebarMenuSubItem>
            </SidebarMenuSub>
            {billingInitialized && userActionPermissions?.manageBilling ? (
              <SidebarMenuSub>
                <SidebarMenuSubItem
                  asChild
                  isActive={pathname === "/settings/billing"}
                >
                  <Link href="/settings/billing">Billing</Link>
                </SidebarMenuSubItem>
              </SidebarMenuSub>
            ) : null}
            {userActionPermissions?.createContent ? (
              <SidebarMenuSub>
                <SidebarMenuSubItem
                  asChild
                  isActive={pathname === "/settings/archive"}
                >
                  <Link href="/settings/archive">Archive</Link>
                </SidebarMenuSubItem>
              </SidebarMenuSub>
            ) : null}
            {userActionPermissions?.manageOrgIntegrations ? (
              <SidebarMenuSub>
                <SidebarMenuSubItem
                  asChild
                  isActive={pathname === "/settings/hris"}
                >
                  <Link href="/settings/hris">HRIS</Link>
                </SidebarMenuSubItem>
              </SidebarMenuSub>
            ) : null}
          </CollapsibleContent>
        </Collapsible>
      )}
    </SidebarMenuItem>
  );
};

export default AdminLeftNavSettingsMenuButton;
