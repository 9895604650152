import { FC } from "react";
import { StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";

type Props = {
  text: string;
  setText: (newText: string) => void;
  question: string;
  placeholder: string;
  loading: boolean;
};

const AIGenerateDescribeModule: FC<Props> = ({
  text,
  setText,
  question,
  placeholder,
  loading,
}) => {
  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      spaceBetweenItems={12}
    >
      <AutoLayout
        direction="vertical"
        flex={1}
        alignSelf="stretch"
        spaceBetweenItems={8}
      >
        <Text type="P2" fontWeight="Medium">
          {question}
        </Text>
        <TextField
          text={text}
          placeholder={placeholder}
          onTextChange={setText}
          disabled={loading}
          multiline
          minRows={15}
          maxRows={15}
          containerStyleDeclaration={styles.textFieldWrapper}
          wrapperStyleDeclaration={styles.textFieldWrapper}
          inputStyleDeclaration={styles.textFieldInput}
          dataTestId="ai-generate-describe-module-textfield"
        />
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  textFieldWrapper: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
  },
  textFieldInput: {
    flex: 1,
    alignSelf: "stretch",
    padding: 20,
    height: "auto",
  },
});

export default AIGenerateDescribeModule;
