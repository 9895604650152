import { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import VerticalDivider from "@src/deprecatedDesignSystem/components/VerticalDivider";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import EmptyState from "@src/deprecatedDesignSystem/components/EmptyState";
import TrainingResourceLibraryItemsPaginatedList from "./TrainingResourceLibraryItemsPaginatedList";
import { gql } from "@apollo/client";
import { useAiGenerateUploadTrainingResourceLibraryItemsQuery } from "./AIGenerateUploadResources.generated";
import { LibraryItemType } from "@src/types.generated";
import {
  getLibraryItemTrainingResourceAvatar,
  getLibraryItemTrainingResourceSubtitle,
  getLibraryItemTrainingResourceTitle,
} from "./utils";
import LibraryItemRow from "@src/components/libraryItemDetailPages/module/createPathContentMembershipMenu/LibraryItemRow";
import { debounce } from "lodash";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";

const PAGINATION_LIMIT = 20;

type Props = {
  loading: boolean;
  selectedTrainingResourceIds: Set<string>;
  setSelectedTrainingResourceIds: (newIds: Set<string>) => void;
};

const AIGenerateUploadResources: FC<Props> = ({
  loading,
  selectedTrainingResourceIds,
  setSelectedTrainingResourceIds,
}) => {
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const input = useMemo(() => {
    return {
      filters: {
        itemType: { value: LibraryItemType.TrainingResource },
      },
      search:
        debouncedSearch.length > 0 ? { value: debouncedSearch } : undefined,
      sort: { column: "name", descending: false },
    };
  }, [debouncedSearch]);
  const {
    data: libraryItemsData,
    loading: libraryItemsLoading,
    fetchMore,
  } = useAiGenerateUploadTrainingResourceLibraryItemsQuery({
    variables: {
      input,
      pagination: {
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });
  const totalTrainingResources = useMemo(() => {
    return libraryItemsData?.LibraryItems.totalCount || 0;
  }, [libraryItemsData?.LibraryItems.totalCount]);
  const trainingResources = useMemo(() => {
    return libraryItemsData?.LibraryItems.objects || [];
  }, [libraryItemsData?.LibraryItems.objects]);
  const hasMoreTrainingResources = useMemo(() => {
    return trainingResources.length < totalTrainingResources;
  }, [trainingResources, totalTrainingResources]);
  const selectedTrainingResources = useMemo(() => {
    return trainingResources.filter(
      (x) =>
        x.trainingResource &&
        selectedTrainingResourceIds.has(x.trainingResource.id),
    );
  }, [selectedTrainingResourceIds, trainingResources]);
  const visibleTrainingResources = useMemo(() => {
    return trainingResources.filter(
      (x) =>
        x.trainingResource &&
        !selectedTrainingResourceIds.has(x.trainingResource.id),
    );
  }, [trainingResources, selectedTrainingResourceIds]);

  const debouncedFetchMore = debounce(() =>
    fetchMore({
      variables: {
        pagination: {
          limit: PAGINATION_LIMIT,
          offset: trainingResources.length,
        },
      },
    }),
  );

  return (
    <AutoLayout
      direction="vertical"
      flex={1}
      alignSelf="stretch"
      spaceBetweenItems={12}
      style={{ overflow: "hidden" }}
    >
      <AutoLayout flex={1} alignSelf="stretch" style={{ overflow: "hidden" }}>
        <AutoLayout
          flex={1}
          alignSelf="stretch"
          borderRadius={20}
          style={{
            overflow: "hidden",
            backgroundColor: deprecatedTones.white,
            border: `1px solid ${deprecatedTones.gray5Alpha}`,
          }}
        >
          <AutoLayout flex={1} alignSelf="stretch" direction="vertical">
            <AutoLayout
              alignSelf="stretch"
              alignmentHorizontal="center"
              alignmentVertical="center"
              height={48}
              padding={"0px 12px"}
              style={{
                borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
              }}
            >
              <TextField
                placeholder="Search..."
                text={search}
                disabled={loading}
                leftIcon="search"
                onTextChange={setSearch}
                containerStyle={{ flex: 1 }}
              />
            </AutoLayout>
            {libraryItemsLoading || trainingResources.length === 0 ? (
              <EmptyState
                icon="file-plus"
                loading={libraryItemsLoading}
                title="No Training Resources"
                subtitle="Add a Training Resource to generate a course outline"
              />
            ) : visibleTrainingResources.length > 0 ? (
              <AutoLayout
                flex={1}
                alignSelf="stretch"
                direction="vertical"
                style={{ overflowY: "scroll" }}
              >
                <TrainingResourceLibraryItemsPaginatedList
                  libraryItems={visibleTrainingResources}
                  loading={loading}
                  hasMore={false}
                  fetchMore={() => {
                    if (hasMoreTrainingResources) {
                      debouncedFetchMore();
                    }
                  }}
                  onClick={(newId) => {
                    setSearch("");
                    const newIds = new Set(selectedTrainingResourceIds).add(
                      newId,
                    );
                    setSelectedTrainingResourceIds(newIds);
                  }}
                />
              </AutoLayout>
            ) : (
              <EmptyState icon="search" title={`"${search}" not found`} />
            )}
          </AutoLayout>
          <VerticalDivider style={{ alignSelf: "stretch", width: 1 }} />
          <AutoLayout
            flex={1}
            alignSelf="stretch"
            direction="vertical"
            style={{
              backgroundColor: deprecatedTones.gray5Alpha,
            }}
          >
            <AutoLayout
              alignSelf="stretch"
              height={48}
              alignmentVertical="center"
              spacingMode="space-between"
              padding={16}
              style={{
                backgroundColor: deprecatedTones.white,
                borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
              }}
            >
              <Text type="P3" fontWeight="Medium">
                Training Resources
              </Text>
              <Text type="P3" fontWeight="Medium" color={deprecatedTones.gray5}>
                {`${selectedTrainingResourceIds.size} selected`}
              </Text>
            </AutoLayout>
            {selectedTrainingResources.length > 0 ? (
              <AutoLayout
                flex={1}
                alignSelf="stretch"
                direction="vertical"
                style={{ overflowY: "scroll" }}
              >
                {selectedTrainingResources.map((x) => {
                  const title = getLibraryItemTrainingResourceTitle(x);
                  const subtitle = getLibraryItemTrainingResourceSubtitle(true);
                  const avatar = getLibraryItemTrainingResourceAvatar(x);

                  return (
                    <LibraryItemRow
                      key={x.trainingResource?.id}
                      rowType="Row"
                      title={title}
                      subtitle={subtitle}
                      avatar={avatar}
                      onClick={() => {
                        if (x.trainingResource) {
                          const newIds = new Set(selectedTrainingResourceIds);
                          newIds.delete(x.trainingResource.id);
                          setSelectedTrainingResourceIds(newIds);
                        }
                      }}
                      showRemoveIcon={true}
                    />
                  );
                })}
              </AutoLayout>
            ) : trainingResources.length > 0 ? (
              <EmptyState
                icon="file"
                title="Select a Training Resource"
                subtitle="Convert any Training Resource into a new Module"
              />
            ) : null}
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

gql`
  query AIGenerateUploadTrainingResourceLibraryItems(
    $input: AdminLibraryInput!
    $pagination: PaginationInput
  ) {
    LibraryItems: AdminLibrary(input: $input, pagination: $pagination) {
      totalCount
      objects {
        ...TrainingResourceLibraryItem
      }
    }
  }
  fragment TrainingResourceLibraryItem on LibraryItem {
    id
    trainingResource {
      ...TrainingResource
    }
  }
`;

export default AIGenerateUploadResources;
