import * as React from "react";
import { IconProps } from "../types";

const GlobeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M9 12C13.0041 12 16.25 10.6569 16.25 9C16.25 7.34315 13.0041 6 9 6C4.99594 6 1.75 7.34315 1.75 9C1.75 10.6569 4.99594 12 9 12Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 16.25C10.6569 16.25 12 13.0041 12 9C12 4.99594 10.6569 1.75 9 1.75C7.34315 1.75 6 4.99594 6 9C6 13.0041 7.34315 16.25 9 16.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 16.25C13.0041 16.25 16.25 13.0041 16.25 9C16.25 4.99594 13.0041 1.75 9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default GlobeIcon;
