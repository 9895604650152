import React, {
  useState,
  useMemo,
  useRef,
  ReactElement,
  useEffect,
} from "react";
import Fuse from "fuse.js";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import useClickOutside from "@hooks/useClickOutside";
import EditableText from "../../ui/EditableText";
import AddInlineButton from "../../ui/AddInlineButton";
import { GroupType } from "../../../types.generated";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import useIsFullAdmin from "@src/hooks/useIsFullAdmin";
import { css, StyleSheet } from "aphrodite";
import Text from "@ui/text";
import Spacer from "@src/components/ui/Spacer";

type ThinGroup = {
  id: number;
  name: string;
};

type Props<T extends ThinGroup> = {
  groups: T[];
  group?: T;
  setGroup: (group: T) => void;
  label?: string;
  groupType: GroupType;
  createGroup?: (name: string) => void;
};

const fuseConfig = {
  keys: ["name"],
};

function GroupPicker<T extends ThinGroup>(props: Props<T>): ReactElement {
  const {
    createGroup,
    groups: availableGroups,
    group,
    setGroup,
    label,
    groupType,
  } = props;
  const [search, setSearch] = useState("");
  const fuse = useMemo(() => {
    return new Fuse(availableGroups, fuseConfig);
  }, [availableGroups]);

  const results = useMemo(() => {
    let groups = availableGroups;
    if (search) {
      groups = fuse.search(search).map((result) => result.item);
    }
    return groups;
  }, [fuse, search, availableGroups]);

  const [resultsVisible, setResultsVisible] = useState(false);
  const isFullAdmin = useIsFullAdmin();
  const resultRef = useRef<HTMLDivElement>(null);
  useClickOutside(resultRef, () => {
    if (resultsVisible) {
      setResultsVisible(false);
    }
  });
  const [addGroupVisible, setAddGroupVisible] = useState(false);
  useEffect(() => {
    if (availableGroups.length === 1 && !group) {
      setGroup(availableGroups[0]);
    }
  }, [availableGroups, setGroup, group]);

  const inputRef = useRef(null);

  const showSelectedGroup = !!group && !resultsVisible;
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)}>
        {label && <div className={css(styles.subHeader)}>{label}</div>}
      </div>

      {!showSelectedGroup && (
        <div className={css(styles.searchContainer)} ref={resultRef}>
          <div className={css(styles.groupSearchContainer)}>
            <input
              className={css(styles.textInput, styles.groupSearch)}
              type="search"
              placeholder={
                groupType
                  ? GroupType.Location
                    ? "Search locations"
                    : "Search roles"
                  : "Search groups"
              }
              value={search}
              ref={inputRef}
              onChange={(val) => {
                setSearch(val.target.value);
              }}
              onFocus={() => {
                setResultsVisible(true);
              }}
            />
            <DeprecatedIcon
              type="search"
              color={deprecatedTones.gray10}
              styleDeclaration={styles.searchIcon}
            />
            <DeprecatedIcon
              type="chevron-down"
              color={deprecatedTones.gray10}
              styleDeclaration={styles.chevronIcon}
            />
          </div>
          {resultsVisible && (
            <div className={css(styles.searchResults)}>
              {results.map((result) => (
                <div
                  data-testid={"group-picker-item"}
                  className={css(
                    styles.resultRow,
                    result.id === group?.id && styles.selected,
                  )}
                  key={result.id}
                  onClick={() => {
                    setGroup(result);
                    setResultsVisible(false);
                  }}
                >
                  <DeprecatedIcon
                    type="pin"
                    color={
                      result.id === group?.id
                        ? deprecatedColors.primary
                        : deprecatedColors.onDisabled
                    }
                    style={{ marginRight: 8 }}
                  />
                  {result.name}
                  {result.id === group?.id && (
                    <DeprecatedIcon
                      type="checkmark"
                      color={deprecatedColors.primary}
                      style={{ marginLeft: "auto" }}
                    />
                  )}
                </div>
              ))}
              {results.length === 0 && (
                <div>
                  <Spacer size={10} />
                  <Text type="P3" styleDeclaration={styles.noResults}>
                    No groups found
                  </Text>
                  <Spacer size={11} />
                </div>
              )}
              {!addGroupVisible &&
                !!groupType &&
                createGroup &&
                isFullAdmin && (
                  <AddInlineButton
                    text={
                      groupType
                        ? GroupType.Location
                          ? "Create location"
                          : "Create role"
                        : "Create group"
                    }
                    onClick={() => {
                      setAddGroupVisible(!addGroupVisible);
                    }}
                  />
                )}

              {addGroupVisible && (
                <div className={css(styles.groupNameInputContainer)}>
                  <EditableText
                    title="New group"
                    titleStyleDeclaration={styles.groupNameInput}
                    editStateEnabled
                    onTitleSave={(name) => {
                      if (createGroup) {
                        createGroup(name);
                      }
                      setAddGroupVisible(false);
                      setResultsVisible(false);
                    }}
                    onLeaveEditState={() => {
                      setAddGroupVisible(false);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showSelectedGroup && (
        <div
          className={css(styles.textInput, styles.selectedGroup)}
          onClick={() => {
            setResultsVisible(true);
            setSearch("");
            setTimeout(() => {
              if (inputRef.current) {
                // @ts-ignore
                inputRef.current.focus();
              }
            }, 5);
          }}
        >
          <DeprecatedIcon
            type="pin"
            color={deprecatedTones.gray10}
            style={{ marginRight: 16 }}
          />
          <Text type="P2" fontWeight="Medium" style={{ flex: 1 }}>
            {group!.name}
          </Text>
          <DeprecatedIcon type="chevron-down" color={deprecatedTones.gray10} />
        </div>
      )}
    </div>
  );
}

export default GroupPicker;

const styles = StyleSheet.create({
  textInput: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    boxSizing: "border-box",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: "8px",
    fontSize: "14px",
    padding: "12px",
    ":focus": {
      boxShadow: `0 0 0 3px ${deprecatedTones.blue3}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
    ":hover:not(:focus):not(.error)": {
      border: `1px solid ${deprecatedTones.gray5}`,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    overflowY: "unset",
    zIndex: 100,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  searchContainer: {
    position: "relative",
  },
  searchResults: {
    position: "absolute",
    boxShadow: "0 9px 39px -21px rgba(0, 0, 0, 0.49)",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    width: "100%",
    backgroundColor: deprecatedTones.white,
    borderRadius: "8px",
    maxHeight: "260px",
    overflow: "auto",
    padding: "8px",
    zIndex: 100,
  },
  resultRow: {
    fontWeight: 500,
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "4px",
    display: "flex",
    height: "40px",
    padding: "12px 4px",
    alignItems: "center",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  noResults: {
    color: deprecatedTones.gray6,
    fontWeight: 400,
    fontSize: "13px",
    padding: "12px",
  },
  groupNameInputContainer: {
    margin: "12px 20px",
  },
  groupNameInput: {
    fontSize: "14px",
    backgroundColor: deprecatedTones.white,
    padding: "4px 12px",
    borderRadius: "8px",
    width: "300px",
  },
  groupSearch: {
    padding: "0 42px",
    borderRadius: "8px",
    width: "100%",
    height: "40px",
    fontSize: "14px",
    verticalAlign: "middle",
  },
  groupSearchContainer: {
    position: "relative",
  },
  searchIcon: {
    position: "absolute",
    left: "12px",
    top: "8px",
    pointerEvents: "none",
  },
  chevronIcon: {
    position: "absolute",
    right: "14px",
    top: "8px",
    pointerEvents: "none",
  },
  selected: {
    color: deprecatedTones.blue9,
  },
  selectedGroup: {
    border: `1px solid ${deprecatedColors.disabled}`,
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: deprecatedColors.onPrimaryContainer,
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
    paddingLeft: "16px",
    position: "relative",
    verticalAlign: "middle",
  },
  subHeader: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "24px",
  },
});
