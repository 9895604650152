import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { css, StyleSheet } from "aphrodite";
import { FC } from "react";
import { LocationGroupType } from "@src/types.generated";
import { locationGroupTypeIcon } from "@components/home/constants";

export type GroupType = "Location" | "Role" | "RoleGroup" | LocationGroupType;

type Props = {
  size: "24px";
  groupType: GroupType;
};

const GroupAvatar: FC<Props> = ({ size, groupType }) => {
  let iconType: DeprecatedIconType = "bookmark";
  const iconColor: DeprecateTone | DeprecatedColor = deprecatedTones.gray7;
  const backgroundColor: DeprecateTone | DeprecatedColor =
    deprecatedTones.gray5Alpha;
  if (groupType === "Location") {
    iconType = "pin";
  } else if (groupType === "Role") {
    iconType = "id-badge";
  } else if (groupType === "RoleGroup") {
    iconType = "users-circle";
  } else {
    iconType = locationGroupTypeIcon[groupType];
  }

  return (
    <div
      style={{
        position: "relative",
        width: size,
        height: size,
        borderRadius: 4,
        backgroundColor: deprecatedTones.gray4Alpha,
        overflow: "hidden",
      }}
    >
      <div
        className={css(styles.iconContainer, styles.largeIconContainer)}
        style={{ backgroundColor }}
      >
        <DeprecatedIcon type={iconType} iconSize={20} color={iconColor} />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    borderRadius: "0 4px 0 0",
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  largeIconContainer: {
    width: "unset",
    height: "unset",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
  },
});

export default GroupAvatar;
