import React from "react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuSkeleton,
} from "@src/ui/sidebar";
import { Button } from "@src/ui/button";

const LoadingLeftNav: React.FC = () => {
  return (
    <Sidebar>
      <SidebarContent>
        <SidebarGroup className="flex flex-col gap-2">
          <Button
            variant="outline"
            asChild
            className="items-start px-0"
            disabled
          >
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuSkeleton className="w-40" showIcon />
              </SidebarMenuItem>
            </SidebarMenu>
          </Button>
          <Button
            variant="outline"
            asChild
            className="items-start px-0"
            disabled
          >
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuSkeleton className="w-40" showIcon />
              </SidebarMenuItem>
            </SidebarMenu>
          </Button>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarMenu>
            {Array.from({ length: 5 }).map((_, index) => (
              <SidebarMenuItem key={index}>
                <SidebarMenuSkeleton showIcon />
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarMenu>
            {Array.from({ length: 5 }).map((_, index) => (
              <SidebarMenuItem key={index}>
                <SidebarMenuSkeleton showIcon />
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
};

export default LoadingLeftNav;
