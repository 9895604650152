import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckInsPageHeaderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckInsPageHeaderQuery = { __typename?: 'Query', CheckIns: { __typename?: 'LibraryItems', totalCount: number } };

export type CheckInsPageHeaderCreateSkillMutationVariables = Types.Exact<{
  input: Types.SkillInput;
}>;


export type CheckInsPageHeaderCreateSkillMutation = { __typename?: 'Mutation', createSkill: { __typename?: 'SkillMutationResult', success: boolean, skill?: { __typename?: 'Skill', id: number, draftVersion?: { __typename?: 'SkillVersion', id: string } | null, libraryItem: { __typename?: 'LibraryItem', id: string } } | null } };


export const CheckInsPageHeaderDocument = gql`
    query CheckInsPageHeader {
  CheckIns: AdminLibrary(input: {filters: {itemType: {value: SKILL}}}) {
    totalCount
  }
}
    `;

/**
 * __useCheckInsPageHeaderQuery__
 *
 * To run a query within a React component, call `useCheckInsPageHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInsPageHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInsPageHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckInsPageHeaderQuery(baseOptions?: Apollo.QueryHookOptions<CheckInsPageHeaderQuery, CheckInsPageHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckInsPageHeaderQuery, CheckInsPageHeaderQueryVariables>(CheckInsPageHeaderDocument, options);
      }
export function useCheckInsPageHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckInsPageHeaderQuery, CheckInsPageHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckInsPageHeaderQuery, CheckInsPageHeaderQueryVariables>(CheckInsPageHeaderDocument, options);
        }
export type CheckInsPageHeaderQueryHookResult = ReturnType<typeof useCheckInsPageHeaderQuery>;
export type CheckInsPageHeaderLazyQueryHookResult = ReturnType<typeof useCheckInsPageHeaderLazyQuery>;
export type CheckInsPageHeaderQueryResult = Apollo.QueryResult<CheckInsPageHeaderQuery, CheckInsPageHeaderQueryVariables>;
export const CheckInsPageHeaderCreateSkillDocument = gql`
    mutation CheckInsPageHeaderCreateSkill($input: SkillInput!) {
  createSkill(input: $input) {
    success
    skill {
      id
      draftVersion {
        id
      }
      libraryItem {
        id
      }
    }
  }
}
    `;
export type CheckInsPageHeaderCreateSkillMutationFn = Apollo.MutationFunction<CheckInsPageHeaderCreateSkillMutation, CheckInsPageHeaderCreateSkillMutationVariables>;

/**
 * __useCheckInsPageHeaderCreateSkillMutation__
 *
 * To run a mutation, you first call `useCheckInsPageHeaderCreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInsPageHeaderCreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInsPageHeaderCreateSkillMutation, { data, loading, error }] = useCheckInsPageHeaderCreateSkillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckInsPageHeaderCreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<CheckInsPageHeaderCreateSkillMutation, CheckInsPageHeaderCreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInsPageHeaderCreateSkillMutation, CheckInsPageHeaderCreateSkillMutationVariables>(CheckInsPageHeaderCreateSkillDocument, options);
      }
export type CheckInsPageHeaderCreateSkillMutationHookResult = ReturnType<typeof useCheckInsPageHeaderCreateSkillMutation>;
export type CheckInsPageHeaderCreateSkillMutationResult = Apollo.MutationResult<CheckInsPageHeaderCreateSkillMutation>;
export type CheckInsPageHeaderCreateSkillMutationOptions = Apollo.BaseMutationOptions<CheckInsPageHeaderCreateSkillMutation, CheckInsPageHeaderCreateSkillMutationVariables>;