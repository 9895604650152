import { useAiUploadMediaGetSupportedImagesMimeTypesQuery } from "@src/components/modals/ai/UploadMedia.generated";
import { useCallback, useMemo } from "react";

type UploadMediaAcceptType = {
  [key: string]: string[];
};

const INITIAL_ACCEPTED_TYPES: UploadMediaAcceptType = {
  "application/pdf": [".pdf"],
  "application/vnd.ms-powerpoint": [".ppt", ".pptm", ".pptx"],
};

type Return = {
  loading: boolean;
  isMediaTypeAccepted: (mediaUrl?: string) => boolean;
  acceptedMediaTypes: Set<string>;
  acceptedMediaTypesObject: UploadMediaAcceptType;
};

const useAcceptedMediaTypes = (): Return => {
  const { data, loading: mimeTypesLoading } =
    useAiUploadMediaGetSupportedImagesMimeTypesQuery();
  const acceptedMediaTypesObject = useMemo(() => {
    const types = INITIAL_ACCEPTED_TYPES;
    (data?.GetSupportedImagesMIMETypes || []).forEach((mimeType) => {
      types[mimeType.mimeType] = [mimeType.ext];
    });
    return types;
  }, [data]);
  const acceptedMediaTypes = useMemo(() => {
    const set: Set<string> = new Set();
    for (const key in acceptedMediaTypesObject) {
      const values = acceptedMediaTypesObject[key];
      values.forEach((value) => set.add(value));
    }
    return set;
  }, [acceptedMediaTypesObject]);

  const isMediaTypeAccepted = useCallback(
    (mediaUrl?: string) => {
      if (!mediaUrl) return false;
      const fileExtension = mediaUrl?.substring(mediaUrl.lastIndexOf("."));
      return acceptedMediaTypes.has(fileExtension);
    },
    [acceptedMediaTypes],
  );

  return {
    loading: mimeTypesLoading,
    acceptedMediaTypes,
    acceptedMediaTypesObject,
    isMediaTypeAccepted,
  };
};

export default useAcceptedMediaTypes;
