import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { AddressFragmentDoc } from '../../../contexts/LocationDetailContext/LocationDetailContext.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InviteQrCodeModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InviteQrCodeModalQuery = { __typename?: 'Query', Locations: { __typename?: 'Locations', objects: Array<{ __typename?: 'Location', id: number, name: string, signupCode?: { __typename?: 'SignupCode', id: number, signupLink: string, signupQrcodeLink: string } | null }> } };

export type CreateLocationInQrCodeModalMutationVariables = Types.Exact<{
  input: Types.CreateLocationInput;
}>;


export type CreateLocationInQrCodeModalMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'LocationMutationResult', success: boolean, location?: { __typename?: 'Location', id: number, name: string, signupCode?: { __typename?: 'SignupCode', id: number, signupLink: string, signupQrcodeLink: string } | null, address?: { __typename?: 'Address', id: string, lineOne: string, lineTwo?: string | null, city: string, usState?: USState | null, country: string, postalCode: string, lat?: number | null, lng?: number | null } | null } | null } };


export const InviteQrCodeModalDocument = gql`
    query InviteQRCodeModal {
  Locations {
    objects {
      id
      name
      signupCode {
        id
        signupLink
        signupQrcodeLink
      }
    }
  }
}
    `;

/**
 * __useInviteQrCodeModalQuery__
 *
 * To run a query within a React component, call `useInviteQrCodeModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteQrCodeModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteQrCodeModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteQrCodeModalQuery(baseOptions?: Apollo.QueryHookOptions<InviteQrCodeModalQuery, InviteQrCodeModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteQrCodeModalQuery, InviteQrCodeModalQueryVariables>(InviteQrCodeModalDocument, options);
      }
export function useInviteQrCodeModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteQrCodeModalQuery, InviteQrCodeModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteQrCodeModalQuery, InviteQrCodeModalQueryVariables>(InviteQrCodeModalDocument, options);
        }
export type InviteQrCodeModalQueryHookResult = ReturnType<typeof useInviteQrCodeModalQuery>;
export type InviteQrCodeModalLazyQueryHookResult = ReturnType<typeof useInviteQrCodeModalLazyQuery>;
export type InviteQrCodeModalQueryResult = Apollo.QueryResult<InviteQrCodeModalQuery, InviteQrCodeModalQueryVariables>;
export const CreateLocationInQrCodeModalDocument = gql`
    mutation CreateLocationInQRCodeModal($input: CreateLocationInput!) {
  createLocation(input: $input) {
    success
    location {
      id
      name
      signupCode {
        id
        signupLink
        signupQrcodeLink
      }
      address {
        ...Address
      }
    }
  }
}
    ${AddressFragmentDoc}`;
export type CreateLocationInQrCodeModalMutationFn = Apollo.MutationFunction<CreateLocationInQrCodeModalMutation, CreateLocationInQrCodeModalMutationVariables>;

/**
 * __useCreateLocationInQrCodeModalMutation__
 *
 * To run a mutation, you first call `useCreateLocationInQrCodeModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationInQrCodeModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationInQrCodeModalMutation, { data, loading, error }] = useCreateLocationInQrCodeModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationInQrCodeModalMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationInQrCodeModalMutation, CreateLocationInQrCodeModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationInQrCodeModalMutation, CreateLocationInQrCodeModalMutationVariables>(CreateLocationInQrCodeModalDocument, options);
      }
export type CreateLocationInQrCodeModalMutationHookResult = ReturnType<typeof useCreateLocationInQrCodeModalMutation>;
export type CreateLocationInQrCodeModalMutationResult = Apollo.MutationResult<CreateLocationInQrCodeModalMutation>;
export type CreateLocationInQrCodeModalMutationOptions = Apollo.BaseMutationOptions<CreateLocationInQrCodeModalMutation, CreateLocationInQrCodeModalMutationVariables>;