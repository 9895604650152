import DateTimeField from "@src/deprecatedDesignSystem/components/DateTimeField";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import TextStyles from "@src/deprecatedDesignSystem/styles/TextStyles";
import { timezoneChoices } from "@src/utils/copy";
import { css, StyleSheet } from "aphrodite";
import { FC } from "react";
import { OrgFormState } from "./useOrgFormState";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import ToggleButton from "@src/deprecatedDesignSystem/components/ToggleButton";
import SingleSelectField from "@src/deprecatedDesignSystem/components/SingleSelectField";
import Spacer from "@src/components/ui/Spacer";
import { isUndefined } from "lodash";

type Props = OrgFormState & { creating?: boolean };

const OrgForm: FC<Props> = (props) => {
  const {
    name,
    deactivatedAt,
    setName,
    setDeactivatedAt,
    startedAt,
    setStartedAt,
    seatsExpected,
    setSeatsExpected,
    timezone,
    setTimezone,
    isTrial,
    setIsTrial,
    creating,
  } = props;
  return (
    <div className={css(styles.form)}>
      <TextField
        text={name}
        label="Name"
        onTextChange={(value) => setName(value)}
      />
      <Spacer size={8} />
      <SingleSelectField
        label="Timezone"
        value={timezone}
        options={timezoneChoices}
        placeholder="US/ET"
        onValueChange={(value) => {
          if (isUndefined(value)) return;
          setTimezone(value);
        }}
      />
      <Spacer size={8} />
      <div className={css(styles.row)}>
        <div className={css(styles.label)}>Deactivated at:</div>
        <DateTimeField value={deactivatedAt} onChange={setDeactivatedAt} />
        {deactivatedAt && (
          <DeprecatedIcon
            type="trash-2"
            onClick={() => {
              setDeactivatedAt(undefined);
            }}
            style={{ marginLeft: 10, position: "absolute" }}
          />
        )}
      </div>
      <div className={css(styles.row)}>
        <div className={css(styles.label)}>
          Started at
          <span style={{ marginLeft: 6 }} className={css(TextStyles.textSmall)}>
            (used for adoption reporting purposes. Do not set this unless this
            is a customer!)
          </span>
        </div>
        <DateTimeField value={startedAt} onChange={setStartedAt} />
        {startedAt && (
          <DeprecatedIcon
            type="trash-2"
            onClick={() => {
              setStartedAt(undefined);
            }}
            style={{ marginLeft: 10, position: "absolute" }}
          />
        )}
      </div>
      <TextField
        text={seatsExpected.toString()}
        label="Expected Employee Count"
        inputType="number"
        onTextChange={(value) => setSeatsExpected(parseInt(value, 10) || 0)}
      />
      <Spacer size={8} />

      {creating && (
        <div className={css(styles.row)}>
          <div className={css(styles.label)}>Is Trial?</div>
          <ToggleButton
            selected={!!isTrial}
            onClick={() => setIsTrial(!isTrial)}
          />
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  form: {
    padding: 12,
    marginBottom: 12,
  },
  label: {
    fontWeight: 600,
    color: deprecatedColors.onBackground,
    fontSize: 12,
    lineHeight: "24px",
    marginRight: 12,
    marginBottom: 8,
  },
  deletebtn: {
    marginLeft: 10,
  },
  row: {
    marginBottom: 16,
  },
});

export default OrgForm;
