import AIGenerateModalAIContext from "@src/components/modals/ai/AIGenerateModalAIAssist";
import {
  CreateAiModuleResultFragment,
  useCreateAiModuleMutation,
} from "@components/modals/ai/AIGenerateModuleModal.generated";
import { useToast } from "@hooks/useToast";
import gql from "graphql-tag";
import { FC } from "react";

type Props = {
  closeModal: () => void;
  onModuleCreated: (createdModule: CreateAiModuleResultFragment) => void;
};

const AIGenerateModuleModal: FC<Props> = ({ closeModal, onModuleCreated }) => {
  const { addErrorToast } = useToast();
  const [createAIModuleMutation, { loading }] = useCreateAiModuleMutation({
    onCompleted: (data) => {
      if (data.createAIModule.success && data.createAIModule.path) {
        onModuleCreated(data.createAIModule.path);
        closeModal();
      } else {
        addErrorToast({
          callsite: "create_ai_module_modal",
        });
      }
    },
    onError: () => {
      addErrorToast({
        callsite: "create_ai_module_modal",
      });
    },
  });
  return (
    <AIGenerateModalAIContext
      title="New Module"
      closeModal={closeModal}
      placeholder="Paste text here. For a high-quality module, at least several paragraphs works best"
      loading={loading}
      generateModuleFromTrainingResources={(ids, brandDetailsId) =>
        createAIModuleMutation({
          variables: {
            input: {
              textList: [],
              trainingResourceUuids: ids,
              brandDetailsId,
            },
          },
        })
      }
      generateModuleFromTextList={(textList, brandDetailsId) =>
        createAIModuleMutation({
          variables: {
            input: {
              textList,
              brandDetailsId,
            },
          },
        })
      }
      generateModuleFromMedia={(mediaUrls, brandDetailsId) =>
        createAIModuleMutation({
          variables: {
            input: {
              textList: [],
              mediaUrls,
              brandDetailsId,
            },
          },
        })
      }
      question="What is your module about?"
      buttonText="Generate module"
    />
  );
};

gql`
  mutation CreateAIModule($input: AIModuleInput!) {
    createAIModule(input: $input) {
      success
      error {
        code
      }
      path {
        ...CreateAIModuleResult
      }
    }
  }

  fragment CreateAIModuleResult on Path {
    id
    draftVersion {
      id
    }
    libraryItem {
      id
    }
  }
`;

export default AIGenerateModuleModal;
