import { UIEvent, useCallback, useState } from "react";

type ScrollProps = {
  onScroll: (event: UIEvent<HTMLElement>) => void;
};

type Return = {
  scrollTop: number;
  scrollProps: ScrollProps;
};

const useScrollTop = (): Return => {
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = useCallback(
    (event: UIEvent<HTMLElement>) =>
      setScrollTop((event.target as HTMLElement).scrollTop),
    [],
  );
  return { scrollTop, scrollProps: { onScroll } };
};

export default useScrollTop;
