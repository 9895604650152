import React from "react";
import Text from "@ui/text";
import useUser from "@hooks/useUser";
import { isInPast } from "@src/utils/time";
import useBranding from "@src/hooks/useBranding";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import { Button } from "@src/ui/button";
import { cn } from "@src/ui/lib/utils";
import CheckmarkCurvedIcon from "@src/ui/icons/12px/checkmark-curved";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
} from "@src/ui/command";
import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";
import { useSidebar } from "@src/ui/sidebar";
import {
  useOrgSwitcherQuery,
  useUpdateMyOrgMutation,
} from "@src/components/layout/top-nav/OrgSwitcher.generated";
import { OrgLogo } from "@src/components/layout/top-nav/OrgLogo";

const LeftNavOrgSwitcher: React.FC = () => {
  const { user } = useUser();
  const [open, setOpen] = React.useState(false);
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const { data, loading, previousData } = useOrgSwitcherQuery({
    variables: {
      input: {
        search:
          debouncedSearch.length > 0 ? { value: debouncedSearch } : undefined,
      },
    },
  });
  const [updateOrg] = useUpdateMyOrgMutation({
    onCompleted: (res) => {
      if (res.updateMyOrg.success) {
        window.location.reload();
      }
    },
  });
  const availableOrgs = React.useMemo(
    () =>
      data?.Me?.availableOrgs.objects ||
      previousData?.Me?.availableOrgs.objects ||
      [],
    [data, previousData],
  );
  const totalCount = React.useMemo(
    () =>
      data?.Me?.totalOrgCount.totalCount ||
      previousData?.Me?.totalOrgCount.totalCount ||
      0,
    [data, previousData],
  );
  const selectOrg = React.useCallback(
    (orgId: number) => {
      updateOrg({
        variables: { orgId },
      });
      setOpen(false);
    },
    [updateOrg],
  );
  const disabled = React.useMemo(
    () => totalCount <= 1 && !loading,
    [totalCount, loading],
  );
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "o" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);
  const { state } = useSidebar();
  const { name, brandDetails } = useBranding();
  const inner = React.useMemo(
    () => (
      <div className="flex self-stretch">
        <Button
          variant="outline"
          className={cn(
            "h-9 w-full flex-1 items-center gap-1.5 overflow-hidden p-0 transition-[width] duration-200 ease-linear",
            disabled && "cursor-default hover:bg-background",
          )}
        >
          <div className="ml-[8px] shrink-0">
            <OrgLogo
              orgId={user?.org?.id || 0}
              size={16}
              logoUrl={
                brandDetails?.logoUrls?.thumb ||
                brandDetails?.logoUrls?.original
              }
            />
          </div>
          <div
            className={cn(
              "flex flex-1 items-center transition-all duration-200 ease-linear overflow-hidden",
              state === "collapsed"
                ? "size-0 opacity-0"
                : "opacity-100 mr-[8px]",
            )}
          >
            <div className="flex flex-1 justify-start">
              <Text
                type="P2"
                fontWeight="Medium"
                className="w-[160px] truncate text-left"
              >
                {name}
              </Text>
            </div>
            {!disabled && <ChevronDownIcon className="text-muted-foreground" />}
          </div>
        </Button>
      </div>
    ),
    [brandDetails, disabled, name, state, user?.org?.id],
  );
  return disabled ? (
    inner
  ) : (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{inner}</PopoverTrigger>
      <PopoverContent className="w-[300px] p-0" align="start" sideOffset={8}>
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Search organizations..."
            value={search}
            onValueChange={setSearch}
          />
          <CommandList>
            {loading ? (
              <CommandItem disabled>Loading...</CommandItem>
            ) : availableOrgs.length === 0 ? (
              <CommandItem disabled>No organizations found</CommandItem>
            ) : (
              availableOrgs.map((org) => (
                <CommandItem
                  key={org.id}
                  onSelect={() => selectOrg(org.id)}
                  className="flex items-center gap-2 py-2"
                >
                  <OrgLogo
                    orgId={org.id}
                    logoUrl={
                      org.brandDetails?.logoUrls?.thumb ||
                      org.brandDetails?.logoUrls?.original
                    }
                  />
                  <Text
                    type="P2"
                    fontWeight="Regular"
                    className={cn(
                      "flex-1 truncate",
                      org.deactivatedAt &&
                        isInPast(org.deactivatedAt) &&
                        "text-gray-7",
                    )}
                  >
                    {org.name}
                  </Text>
                  {org.id === user?.org?.id && (
                    <CheckmarkCurvedIcon className="text-gray-9" />
                  )}
                </CommandItem>
              ))
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default LeftNavOrgSwitcher;
