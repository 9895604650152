import React from "react";
import { useRouter } from "next/router";
import { Button } from "@src/ui/button";
import SearchIcon from "@src/ui/icons/18px/search";
import { cn } from "@src/ui/lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import { textVariants } from "@src/ui/text";
import Text from "@src/ui/text";
import { Command, CommandInput, CommandList } from "@src/ui/command";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import useAllJumpToItems, {
  JumpToItem,
} from "@components/ui/jumpToBar/hooks/useAllJumpToItems";
import useAllRecentlyViewedItems from "@components/ui/jumpToBar/hooks/useAllRecentlyViewedItems";
import {
  getLinkToItem,
  itemDescription,
} from "@src/components/layout/top-nav/utils";
import JumpToBarItems from "@src/components/layout/top-nav/JumpToBarItems";

const JumpToBar: React.FC = () => {
  const router = useRouter();
  const [open, setOpen] = React.useState(false);
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const { items: allJumpToItems, loading } = useAllJumpToItems({
    search: debouncedSearch,
  });
  const { items: recentlyViewedItems } = useAllRecentlyViewedItems();
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);
  const selectItem = React.useCallback(
    (item: JumpToItem) => {
      const link = getLinkToItem(item);
      setOpen(false);
      setSearch("");
      router.push(link);
    },
    [router, setSearch],
  );
  return (
    <div className="flex w-[540px] items-center gap-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="relative flex-1 pl-8"
            data-testid="jump-to-bar-trigger"
            asChild
          >
            <div className="flex items-center justify-start">
              <SearchIcon className="absolute left-2 top-1/2 -translate-y-1/2 text-muted-foreground" />
              <Text type="P2" className="flex-1 text-muted-foreground">
                Quick find...
              </Text>
              <div
                className={cn(
                  "absolute right-2 top-1/2 -translate-y-1/2 text-gray-9 bg-gray-2 px-1.5 py-0.5 rounded-md",
                  textVariants({ variant: "P3" }),
                )}
              >
                ⌘K
              </div>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[540px] p-0"
          align="start"
          sideOffset={-36}
        >
          <Command shouldFilter={false}>
            <CommandInput
              placeholder="Search"
              value={search}
              onValueChange={setSearch}
              data-testid="jump-to-bar-input"
            />
            <CommandList>
              <JumpToBarItems
                query={search}
                recentlyViewedItems={recentlyViewedItems}
                allItems={allJumpToItems}
                loading={loading}
                onSelectItem={selectItem}
                itemDescription={itemDescription}
                closePopover={() => setOpen(false)}
              />
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default JumpToBar;
