import Modal from "@components/modals/Modal";
import OrgForm from "@components/modals/orgManagement/OrgForm";
import {
  useOrgForUpdateQuery,
  useUpdateOrgMutation,
} from "@components/modals/orgManagement/UpdateOrgModal.generated";
import { useOrgFormState } from "@components/modals/orgManagement/useOrgFormState";
import { OrgFormStateFragment } from "@components/modals/orgManagement/useOrgFormState.generated";
import { useModal } from "@hooks/useModal";
import { launchConfetti } from "@utils/confetti";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { FC } from "react";

const UpdateOrgModalContainer: FC = () => {
  const { data } = useOrgForUpdateQuery();

  if (!data?.Me?.user.org) {
    return null;
  }
  return <UpdateOrgModal org={data.Me?.user.org} />;
};

type UpdateOrgModalProps = {
  org: OrgFormStateFragment & { id: number };
};
const UpdateOrgModal: FC<UpdateOrgModalProps> = ({ org }) => {
  const state = useOrgFormState(org);
  const { closeModal } = useModal();
  const [updateOrg] = useUpdateOrgMutation({
    onCompleted: (data) => {
      if (data.updateOrg.success) {
        closeModal();
        launchConfetti();
      }
    },
  });
  const { name, deactivatedAt, startedAt, seatsExpected, timezone } = state;

  return (
    <Modal
      title="Update Current Org"
      style={{ width: 460 }}
      confirmButtonProps={{
        disabled: name === "",
        copy: `Save`,
        onClick: () => {
          org &&
            updateOrg({
              variables: {
                orgId: org.id,
                input: {
                  name,
                  seatsExpected: seatsExpected || 0,
                  deactivatedAt: deactivatedAt
                    ? DateTime.fromJSDate(deactivatedAt).toISO()
                    : null,
                  startedAt: startedAt
                    ? DateTime.fromJSDate(startedAt).toISO()
                    : null,
                  timezone,
                },
              },
            });
        },
      }}
    >
      <OrgForm {...state} />
    </Modal>
  );
};

gql`
  query OrgForUpdate {
    Me {
      user {
        org {
          id
          ...OrgFormState
        }
      }
    }
  }
  mutation UpdateOrg($orgId: Int!, $input: OrgInput!) {
    updateOrg(orgId: $orgId, input: $input) {
      success
      org {
        id
        name
        seatsExpected
        deactivatedAt
        startedAt
        timezone
      }
    }
  }
`;

export default UpdateOrgModalContainer;
