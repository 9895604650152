import HomeOutlineIcon from "@src/ui/icons/18px/home-outline";
import ChatBubbleOutlineIcon from "@src/ui/icons/18px/chat-bubble-outline";
import LaptopChartsOutlineIcon from "@src/ui/icons/18px/laptop-charts-outline";
import ShopOutlineIcon from "@src/ui/icons/18px/shop-outline";
import StudentIcon from "@src/ui/icons/18px/student";
import BookOpenOutlineIcon from "@src/ui/icons/18px/book-open-outline";
import BookmarkOutlineIcon from "@src/ui/icons/18px/bookmark-outline";
import CircleCheckmarkOutlineIcon from "@src/ui/icons/18px/circle-checkmark-outline";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";
import IdBadgeOutlineIcon from "@src/ui/icons/18px/id-badge-outline";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";
import TagOutlineIcon from "@src/ui/icons/18px/tag-outline";
import BoltOutlineIcon from "@src/ui/icons/18px/bolt-outline";
import AsteriskIcon from "@src/ui/icons/18px/asterisk";
import FileSendOutlineIcon from "@src/ui/icons/18px/file-send-outline";
import GearOutlineIcon from "@src/ui/icons/18px/gear-outline";
import { RouteItem } from "./useAllJumpToItems";

const routeItems: RouteItem[] = [
  {
    id: "home",
    name: "Home",
    route: { pathname: "/" },
    type: "route",
    icon: HomeOutlineIcon,
  },
  {
    id: "messages",
    name: "Messages",
    route: { pathname: "/messages" },
    type: "route",
    icon: ChatBubbleOutlineIcon,
  },
  {
    id: "reporting",
    name: "Reporting",
    route: { pathname: "/reporting" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "marketplace",
    name: "Marketplace",
    route: { pathname: "/marketplace" },
    type: "route",
    icon: ShopOutlineIcon,
  },
  {
    id: "my-training",
    name: "My Training",
    route: { pathname: "/my-training" },
    type: "route",
    icon: StudentIcon,
  },
  {
    id: "library-modules",
    name: "Modules",
    route: { pathname: "/library/modules" },
    type: "route",
    icon: BookOpenOutlineIcon,
  },
  {
    id: "library-courses",
    name: "Courses",
    route: { pathname: "/library/courses" },
    type: "route",
    icon: BookmarkOutlineIcon,
  },
  {
    id: "library-check-ins",
    name: "Check-ins",
    route: { pathname: "/library/check-ins" },
    type: "route",
    icon: CircleCheckmarkOutlineIcon,
  },
  {
    id: "library-resources",
    name: "Resources",
    route: { pathname: "/library/resources" },
    type: "route",
    icon: FileTextOutlineIcon,
  },
  {
    id: "locations",
    name: "Locations",
    route: { pathname: "/locations" },
    type: "route",
    icon: PinsOutlineIcon,
  },
  {
    id: "locations-franchises",
    name: "Franchises",
    route: { pathname: "/locations/franchises" },
    type: "route",
    icon: PinsOutlineIcon,
  },
  {
    id: "locations-brands",
    name: "Brands",
    route: { pathname: "/locations/brands" },
    type: "route",
    icon: PinsOutlineIcon,
  },
  {
    id: "locations-regions",
    name: "Regions",
    route: { pathname: "/locations/regions" },
    type: "route",
    icon: PinsOutlineIcon,
  },
  {
    id: "roles",
    name: "Roles",
    route: { pathname: "/roles" },
    type: "route",
    icon: IdBadgeOutlineIcon,
  },
  {
    id: "roles-departments",
    name: "Departments",
    route: { pathname: "/roles/departments" },
    type: "route",
    icon: IdBadgeOutlineIcon,
  },
  {
    id: "users",
    name: "Users",
    route: { pathname: "/users" },
    type: "route",
    icon: UsersOutlineIcon,
  },
  {
    id: "reporting-builder",
    name: "Report Builder",
    route: { pathname: "/reporting/builder" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "tags",
    name: "Tags",
    route: { pathname: "/tags" },
    type: "route",
    icon: TagOutlineIcon,
  },
  {
    id: "automations",
    name: "Automations",
    route: { pathname: "/automations" },
    type: "route",
    icon: BoltOutlineIcon,
  },
  {
    id: "paths",
    name: "Paths",
    route: { pathname: "/paths" },
    type: "route",
    icon: AsteriskIcon,
  },
  {
    id: "access",
    name: "Access",
    route: { pathname: "/access" },
    type: "route",
    icon: FileSendOutlineIcon,
  },

  {
    id: "settings",
    name: "Settings",
    route: { pathname: "/settings" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "reporting-compliance-all",
    name: "Reporting > Compliance",
    route: { pathname: "/reporting/compliance/all" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-course-feedback",
    name: "Reporting > Course Feedback",
    route: { pathname: "/reporting/course-feedback" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-location-completion",
    name: "Reporting > Location Completion Grid",
    route: { pathname: "/reporting/location-completion-grid" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-user-completion",
    name: "Reporting > User Completion Grid",
    route: { pathname: "/reporting/user-completion-grid" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-message-reads",
    name: "Reporting > Message Engagement",
    route: { pathname: "/reporting/message-reads-grid" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-location-leaderboard",
    name: "Location Leaderboard",
    route: { pathname: "/reporting/location-leaderboard" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-manager-leaderboard",
    name: "Reporting > Manager Leaderboard",
    route: { pathname: "/reporting/manager-leaderboard" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "reporting-trainee-leaderboard",
    name: "Reporting > Trainee Leaderboard",
    route: { pathname: "/reporting/trainee-leaderboard" },
    type: "route",
    icon: LaptopChartsOutlineIcon,
  },
  {
    id: "settings-brand",
    name: "Settings > Brand",
    route: { pathname: "/settings/brand" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "settings-ai-assist",
    name: "Settings > AI Assist",
    route: { pathname: "/settings/ai-assist" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "settings-compliance",
    name: "Wage & Hour Settings",
    route: { pathname: "/settings/compliance" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "settings-language",
    name: "Settings > Language",
    route: { pathname: "/settings/language" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "settings-billing",
    name: "Settings > Billing",
    route: { pathname: "/settings/billing" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "settings-archive",
    name: "Settings > Archive",
    route: { pathname: "/settings/archive" },
    type: "route",
    icon: GearOutlineIcon,
  },
  {
    id: "settings-hris",
    name: "Settings > HRIS",
    route: { pathname: "/settings/hris" },
    type: "route",
    icon: GearOutlineIcon,
  },
];

const defaultRouteItemIds = [
  "home",
  "library-modules",
  "reporting",
  "messages",
  "users",
];
export const defaultRouteItems = routeItems.filter((item) =>
  defaultRouteItemIds.includes(item.id.toString()),
);

import useActionPermissions from "@src/hooks/useActionPermissions";
import { useMemo } from "react";
import useLocationGroups from "@src/hooks/useLocationGroups";
import { LocationGroupType } from "@src/types.generated";
import { useBillingInitialized } from "@src/hooks/billing/useBillingInitialized";

export const useFilteredRouteItems = (): RouteItem[] => {
  const { userActionPermissions } = useActionPermissions();
  const { locationGroupTypes } = useLocationGroups();
  const billingEnabled = useBillingInitialized();
  return useMemo(() => {
    return routeItems.filter((item) => {
      switch (item.id) {
        case "locations-franchises":
          return locationGroupTypes.includes(LocationGroupType.Franchise);
        case "locations-brands":
          return locationGroupTypes.includes(LocationGroupType.Brand);
        case "reporting":
          return userActionPermissions?.viewReporting;
        case "marketplace":
          return true; // Always visible based on AdminLeftNavMainMenu.tsx
        case "settings":
          return userActionPermissions?.changeOrgSettings;
        case "settings-billing":
          return billingEnabled && userActionPermissions?.manageBilling;
        case "settings-hris":
          return userActionPermissions?.manageOrgIntegrations;
        case "settings-archive":
          return userActionPermissions?.createContent;
        case "users":
          return true; // Always visible based on AdminLeftNavPeopleMenu.tsx
        default:
          return true;
      }
    });
  }, [userActionPermissions, locationGroupTypes, billingEnabled]);
};

export default routeItems;
