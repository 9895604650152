import * as React from "react";
import { IconProps } from "../types";

const AsteriskIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.00002 16.5C8.58602 16.5 8.25002 16.164 8.25002 15.75V2.25C8.25002 1.836 8.58602 1.5 9.00002 1.5C9.41402 1.5 9.75002 1.836 9.75002 2.25V15.75C9.75002 16.164 9.41402 16.5 9.00002 16.5Z"
          fill={color}
        />
        <path
          d="M14.845 13.1249C14.718 13.1249 14.589 13.0929 14.471 13.0239L2.77901 6.27393C2.42101 6.06693 2.29801 5.60793 2.50501 5.24993C2.71101 4.89193 3.17001 4.76693 3.52901 4.97593L15.22 11.7259C15.578 11.9329 15.701 12.3919 15.494 12.7499C15.355 12.9909 15.103 13.1249 14.844 13.1249H14.845Z"
          fill={color}
        />
        <path
          d="M3.15501 13.125C2.89501 13.125 2.64301 12.991 2.50501 12.75C2.29801 12.391 2.42101 11.933 2.77901 11.726L14.471 4.976C14.831 4.768 15.288 4.893 15.495 5.25C15.702 5.609 15.579 6.067 15.221 6.274L3.52901 13.024C3.41101 13.092 3.28201 13.125 3.15501 13.125Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default AsteriskIcon;
