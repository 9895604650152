import React from "react";
import Link from "next/link";

import ChatBubbleOutlineIcon from "@src/ui/icons/18px/chat-bubble-outline";
import HomeOutlineIcon from "@src/ui/icons/18px/home-outline";
import LaptopChartsOutlineIcon from "@src/ui/icons/18px/laptop-charts-outline";
import ShopOutlineIcon from "@src/ui/icons/18px/shop-outline";
import StudentIcon from "@src/ui/icons/18px/student";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@src/ui/sidebar";
import useActionPermissions from "@src/hooks/useActionPermissions";

import { useRouter } from "next/router";
import AdminLeftNavSettingsMenuButton from "@src/components/layout/left-nav/admin/AdminLeftNavSettingsMenuButton";

const AdminLeftNavMainMenu: React.FC = () => {
  const { pathname } = useRouter();
  const { userActionPermissions } = useActionPermissions();
  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              tooltip="Home"
              isActive={pathname === "/"}
            >
              <Link href="/">
                <HomeOutlineIcon />
                Home
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {userActionPermissions?.viewReporting && (
            <SidebarMenuItem>
              <SidebarMenuButton
                asChild
                tooltip="Reporting"
                isActive={pathname === "/reporting"}
              >
                <Link href="/reporting">
                  <LaptopChartsOutlineIcon />
                  Reporting
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              tooltip="Messages"
              isActive={pathname === "/messages"}
            >
              <Link href="/messages">
                <ChatBubbleOutlineIcon />
                Messages
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              tooltip="Marketplace"
              dataTestId="marketplace-button"
              isActive={pathname === "/marketplace"}
            >
              <Link href="/marketplace">
                <ShopOutlineIcon />
                Marketplace
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton
              asChild
              tooltip="My Training"
              isActive={pathname === "/my-training"}
            >
              <Link href="/my-training">
                <StudentIcon />
                My Training
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {userActionPermissions?.changeOrgSettings && (
            <AdminLeftNavSettingsMenuButton />
          )}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
};

export default AdminLeftNavMainMenu;
