import { UserType } from "@src/types.generated";
import { useState } from "react";

type Location = {
  id: number;
  name: string;
};

type Role = Location;

type InviteOrEditUserModalPerson = {
  id: number;
  language: Language;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  email?: string | null | undefined;
  jobTitle?: string | null | undefined;
  userType: UserType;
  phoneNumber: string;
  locations: Location[];
  roles: Role[];
};
type Return = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  jobTitle: string;
  language: Language;
  userType: UserType;
  roleIds: number[];
  locationIds: number[];
  setFirstName: (x: string) => void;
  setLastName: (x: string) => void;
  setPhoneNumber: (x: string) => void;
  setEmail: (x: string) => void;
  setJobTitle: (x: string) => void;
  setLanguage: (x: Language) => void;
  setUserType: (x: UserType) => void;
  setRoleIds: (x: number[]) => void;
  setLocationIds: (x: number[]) => void;
};

const useUserState = (
  user?: InviteOrEditUserModalPerson,
  initialLocationIds: number[] = [],
): Return => {
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");

  const [phoneNumber, setPhoneNumber] = useState<string>(
    user?.phoneNumber || "",
  );
  const [locationIds, setLocationIds] = useState(
    user?.locations.map((l) => l.id) || initialLocationIds,
  );
  const [roleIds, setRoleIds] = useState(user?.roles.map((r) => r.id) || []);
  const [userType, setUserType] = useState(user?.userType || UserType.Manager);
  const [language, setLanguage] = useState(user?.language || "en");
  const [email, setEmail] = useState(user?.email || "");
  const [jobTitle, setJobTitle] = useState(user?.jobTitle || "");
  return {
    firstName,
    lastName,
    phoneNumber,
    email,
    jobTitle,
    language,
    userType,
    roleIds,
    locationIds,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setEmail,
    setJobTitle,
    setLanguage,
    setUserType,
    setRoleIds,
    setLocationIds,
  };
};

export default useUserState;
