import React from "react";
import Text from "@ui/text";
import { cn } from "@src/ui/lib/utils";

type Props = {
  count?: number;
  className?: string;
  size: 12 | 24;
};

const NotificationDot: React.FC<Props> = (props) => {
  return (
    <div
      style={{ width: props.size, height: props.size }}
      className={cn(
        `bg-destructive rounded-full`,
        !!props.count && "flex items-center justify-center",
        props.className,
      )}
    >
      {props.count && (
        <Text type="P3" fontWeight="SemiBold" className="text-white">
          {props.count}
        </Text>
      )}
    </div>
  );
};

export default NotificationDot;
