import * as React from "react";
import { IconProps } from "../types";

const TriangleWarningOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M7.63801 3.49498L2.21301 12.891C1.60801 13.939 2.36401 15.25 3.57501 15.25H14.425C15.636 15.25 16.392 13.94 15.787 12.891L10.362 3.49498C9.75701 2.44698 8.24301 2.44698 7.63801 3.49498Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00002 6.5V10"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00002 13.5691C8.44802 13.5691 8.00002 13.1201 8.00002 12.5691C8.00002 12.0181 8.44802 11.5691 9.00002 11.5691C9.55202 11.5691 10 12.0181 10 12.5691C10 13.1201 9.55202 13.5691 9.00002 13.5691Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default TriangleWarningOutlineIcon;
