import Modal from "@components/modals/Modal";
import Text from "@ui/text";
import { FC } from "react";

type Props = {
  permissionType: string;
};

const NoPermissionModal: FC<Props> = ({ permissionType }) => {
  return (
    <Modal title="Insufficient Permissions" style={{ width: 375, height: 500 }}>
      <Text style={{ padding: 12 }} type="P1" multiline>
        You do not have permission to {permissionType}. Please contact an admin
        if you think this is a mistake.
      </Text>
    </Modal>
  );
};

export default NoPermissionModal;
