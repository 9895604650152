import { FC } from "react";
import Link from "next/link";
import { gql } from "@apollo/client";

import Text from "@ui/text";
import TriangleWarningOutlineIcon from "@src/ui/icons/18px/triangle-warning-outline";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { useBillingBannerQuery } from "@src/components/layout/AddPaymentMethodBanner.generated";

export const AddPaymentMethodBanner: FC = () => {
  const { userActionPermissions } = useActionPermissions();
  const { data } = useBillingBannerQuery({
    skip: !userActionPermissions?.manageBilling,
  });
  const needsPaymentMethod =
    data &&
    data.BillingAccounts.length > 0 &&
    data.BillingAccounts.filter((ba) => ba.paymentMethod).length === 0;
  if (!data || !needsPaymentMethod || data?.Me?.user?.org?.paysManually) {
    return null;
  }
  return (
    <div className="mt-3 flex flex-1">
      <Link
        href="/settings/billing"
        className="flex flex-1 items-center justify-center gap-2 self-stretch rounded-lg bg-yellow-2 p-3"
      >
        <TriangleWarningOutlineIcon className="text-yellow-12" />
        <Text type="P2" fontWeight="Medium" className="text-yellow-12">
          <span className="underline">Add a payment method</span> to continue
          using Opus
        </Text>
      </Link>
    </div>
  );
};

gql`
  query BillingBanner {
    Me {
      user {
        id
        org {
          id
          paysManually
        }
      }
    }
    BillingAccounts(input: {}) {
      id
      paymentMethod {
        id
      }
    }
  }
`;
