import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
import {
  CreatePathMutation,
  CreatePathMutationVariables,
  useCreatePathMutation,
} from "@hooks/useNewPath.generated";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import { route } from "nextjs-routes";

export const useNewPath = (
  onComplete?: () => void,
): ((
  options?: Apollo.MutationHookOptions<
    CreatePathMutation,
    CreatePathMutationVariables
  >,
) => void) => {
  const [createPath] = useCreatePathMutation({
    variables: {
      input: {
        name: "",
      },
    },
    refetchQueries: CONTENT_LIBRARY_REFETCH_QUERIES,
    onCompleted(data) {
      onComplete ? onComplete() : null;
      if (data?.createPath?.path?.id) {
        window.open(
          route({
            pathname: "/library/library-item/[id]",
            query: { id: data.createPath.path.libraryItem.id },
          }),
          "_blank",
        );
      }
    },
  });
  return createPath;
};

gql`
  mutation createPath($input: PathInput!) {
    createPath(input: $input) {
      success
      path {
        ...Path
      }
    }
  }
`;
