import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitializeBillingMutationVariables = Types.Exact<{
  input: Types.InitializeBillingInput;
}>;


export type InitializeBillingMutation = { __typename?: 'Mutation', initializeBilling: { __typename?: 'OrgMutationResult', success: boolean } };


export const InitializeBillingDocument = gql`
    mutation InitializeBilling($input: InitializeBillingInput!) {
  initializeBilling(input: $input) {
    success
  }
}
    `;
export type InitializeBillingMutationFn = Apollo.MutationFunction<InitializeBillingMutation, InitializeBillingMutationVariables>;

/**
 * __useInitializeBillingMutation__
 *
 * To run a mutation, you first call `useInitializeBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeBillingMutation, { data, loading, error }] = useInitializeBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitializeBillingMutation(baseOptions?: Apollo.MutationHookOptions<InitializeBillingMutation, InitializeBillingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeBillingMutation, InitializeBillingMutationVariables>(InitializeBillingDocument, options);
      }
export type InitializeBillingMutationHookResult = ReturnType<typeof useInitializeBillingMutation>;
export type InitializeBillingMutationResult = Apollo.MutationResult<InitializeBillingMutation>;
export type InitializeBillingMutationOptions = Apollo.BaseMutationOptions<InitializeBillingMutation, InitializeBillingMutationVariables>;