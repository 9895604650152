import Spacer from "@components/ui/Spacer";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text, { TextFontWeight } from "@ui/text";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import React, { FC, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { isNotNullOrUndefined } from "@utils/typeguards";

type Props = {
  type: "Pill" | "Underline";
  icon?: DeprecatedIconType;
  iconSize?: number;
  iconComponent?: JSX.Element;
  height: "32px" | "40px";
  text: string;
  onClick?: () => void;
  styleDeclaration?: StyleDeclaration;
  disabled?: boolean;
  fontWeight?: TextFontWeight;
  active?: boolean;
  ellipsis?: boolean;
  textProps?: Partial<React.ComponentProps<typeof Text>>;
  count?: number;
};

type ConditionalProps =
  | { type: "Underline"; borderRadius?: never }
  | { type: "Pill"; borderRadius?: "8px" | "40px" | "64px" };

const Tab: FC<Props & ConditionalProps> = ({
  type = "Pill",
  icon,
  iconSize = 20,
  iconComponent,
  height = "32px",
  borderRadius = "8px",
  text,
  onClick,
  styleDeclaration,
  disabled = false,
  fontWeight = "Medium",
  active,
  ellipsis,
  textProps,
  count,
}) => {
  const textColor = useMemo(() => {
    if (type === "Pill") {
      if (active) {
        return deprecatedTones.gray11;
      } else {
        return deprecatedTones.gray8;
      }
    } else {
      if (active) {
        return deprecatedTones.black;
      } else {
        return deprecatedTones.gray6;
      }
    }
  }, [type, active]);
  const IconComponentOrIcon = useMemo(() => {
    if (iconComponent) {
      return iconComponent;
    }
    if (icon) {
      return (
        <DeprecatedIcon
          type={icon}
          color={deprecatedTones.gray8}
          iconSize={iconSize}
        />
      );
    }
    return null;
  }, [iconComponent, icon, iconSize]);
  return (
    <button
      className={css(
        styles.container,
        styleDeclaration,
        type === "Pill" && styles.pill,
        type === "Underline" && !disabled && styles.underline,
        disabled && styles.disabled,
        active &&
          (type === "Pill" ? styles.pillActive : styles.underlineActive),
      )}
      style={{
        height,
        borderRadius,
      }}
      onClick={!disabled ? onClick : undefined}
    >
      {IconComponentOrIcon}
      {IconComponentOrIcon ? <Spacer axis="horizontal" size={10} /> : null}
      <Text
        ellipsis={ellipsis}
        ellipsisParentFlexDirection="col"
        type={type === "Underline" ? "P1" : "P2"}
        fontWeight={fontWeight}
        color={textColor}
        {...textProps}
        style={{
          flex: 1,
          alignSelf: "center",
          textAlign: "start",
          ...textProps?.style,
        }}
      >
        {text}
      </Text>
      {isNotNullOrUndefined(count) && (
        <AutoLayout marginLeft={6}>
          <TabCount count={count} />
        </AutoLayout>
      )}
      {type === "Underline" ? <Spacer size={4} /> : null}
    </button>
  );
};

export const TabCount: FC<{
  count: number;
}> = (props) => {
  return (
    <AutoLayout
      alignmentVertical={"center"}
      alignmentHorizontal={"center"}
      height={20}
      padding={4}
      style={{
        borderRadius: 6,
        backgroundColor: deprecatedTones.gray4Alpha,
        minWidth: 16,
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <Text type={"P3"} fontWeight={"SemiBold"} color={deprecatedTones.gray8}>
        {props.count}
      </Text>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 0,
    backgroundColor: "transparent",
    userSelect: "none",
  },
  cursor: {
    cursor: "pointer",
  },
  pill: {
    outline: "none",
    width: "100%",
    justifyContent: "flex-start",
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.white,
    },
  },
  pillActive: {
    backgroundColor: "white",
    ":hover": {
      backgroundColor: "white",
    },
  },
  underline: {
    borderRadius: 0,
    padding: 0,
    borderBottom: `2px solid transparent`,
    cursor: "pointer",
    ":hover": {
      borderBottom: `2px solid ${deprecatedTones.gray7}`,
    },
  },
  disabled: {
    cursor: "not-allowed",
  },
  underlineActive: {
    borderBottom: `2px solid ${deprecatedTones.black}`,
    ":hover": {
      borderBottom: `2px solid ${deprecatedTones.black}`,
    },
  },
});

export default Tab;
