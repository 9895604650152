import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { getPillColorsFromId } from "@src/deprecatedDesignSystem/components/Pill";
import { FC } from "react";

type Props = {
  id: string;
  size?: "24px";
};

const TagAvatar: FC<Props> = ({ id, size = "24px" }) => {
  const { backgroundColor, iconColor } = getPillColorsFromId(id);
  return (
    <AutoLayout
      style={{
        width: size,
        height: size,
        borderRadius: 4,
        backgroundColor,
        overflow: "hidden",
      }}
    >
      <DeprecatedIcon type="tag" iconSize={16} color={iconColor} />
    </AutoLayout>
  );
};

export default TagAvatar;
