import { FC, useContext, useEffect } from "react";
import Link from "next/link";
import { useTrackEventMutation } from "../layout/operations.generated";
import { ApolloError } from "@apollo/client";
import UserContext from "../../contexts/UserContext";
import { useRouter } from "next/router";
import { css, StyleSheet } from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import Spacer from "./Spacer";

type Props = {
  error: ApolloError;
};

const LoadingError: FC<Props> = ({ error }) => {
  const [trackEventMutation] = useTrackEventMutation();
  const router = useRouter();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (user) {
      trackEventMutation({
        variables: {
          label: `error_graphql`,
          payload: { error, asPath: router.asPath, pathname: router.pathname },
        },
      });
    }
  }, [user, error, trackEventMutation, router]);
  return <ErrorImg />;
};

export const ErrorImg: FC = () => {
  return (
    <AutoLayout
      direction="vertical"
      styleDeclaration={styles.loadingError}
      alignmentHorizontal="center"
    >
      <img
        className={css(styles.errorImage)}
        src="/images/loading-error.svg"
        alt="loading-error"
      />
      <Spacer size={20} />
      <Text type="H1">Could not load data</Text>
      <Spacer size={16} />
      <Text type="P1" styleDeclaration={styles.subtitle} multiline={true}>
        If this keeps happening, please reach out to{" "}
        <a className={css(styles.link)} href="mailto:support@opus.so">
          support@opus.so
        </a>
      </Text>
      <Spacer size={16} />
      <p className={css(styles.subtitle)}>
        <Text type="P1">
          <Link href="/" className={css(styles.link)}>
            Go home
          </Link>
        </Text>
      </p>
    </AutoLayout>
  );
};

export default LoadingError;

const styles = StyleSheet.create({
  loadingError: {
    textAlign: "center",
  },
  subtitle: {
    color: deprecatedTones.gray11Alpha,
    width: "300px",
  },
  link: {
    color: deprecatedTones.blue11,
    textDecoration: "none",
  },
  errorImage: {
    overflow: "hidden",
  },
});
