import React from "react";

import IdBadgeOutlineIcon from "@src/ui/icons/18px/id-badge-outline";
import {
  useSidebar,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarMenuSub,
  SidebarMenuSubItem,
} from "@src/ui/sidebar";
import {
  TooltipMenu,
  TooltipMenuContent,
  TooltipMenuItem,
  TooltipMenuTrigger,
} from "@src/ui/tooltip-menu";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@src/ui/collapsible";

const AdminLeftNavRolesMenuButton: React.FC = () => {
  const { pathname } = useRouter();
  const { state } = useSidebar();
  const RolesMenuButton = React.useMemo(
    () => (
      <SidebarMenuButton
        asChild
        tooltip="Roles"
        dataTestId="roles-button"
        isActive={
          pathname.includes("/roles") &&
          !pathname.includes("/roles/departments")
        }
      >
        <Link href="/roles">
          <IdBadgeOutlineIcon />
          Roles
        </Link>
      </SidebarMenuButton>
    ),
    [pathname],
  );
  return (
    <SidebarMenuItem>
      {state === "collapsed" ? (
        <TooltipMenu>
          <TooltipMenuTrigger asChild>{RolesMenuButton}</TooltipMenuTrigger>
          <TooltipMenuContent align="center" side="right">
            <TooltipMenuItem asChild>
              <Link href="/roles">Roles</Link>
            </TooltipMenuItem>
            <TooltipMenuItem asChild>
              <Link href="/roles/departments">Departments</Link>
            </TooltipMenuItem>
          </TooltipMenuContent>
        </TooltipMenu>
      ) : (
        <Collapsible
          className="group/collapsible"
          open={pathname.startsWith("/roles")}
        >
          <CollapsibleTrigger asChild>{RolesMenuButton}</CollapsibleTrigger>
          <CollapsibleContent>
            <SidebarMenuSub>
              <SidebarMenuSubItem
                asChild
                dataTestId="departments-button"
                isActive={pathname.includes("/roles/departments")}
              >
                <Link href="/roles/departments">Departments</Link>
              </SidebarMenuSubItem>
            </SidebarMenuSub>
          </CollapsibleContent>
        </Collapsible>
      )}
    </SidebarMenuItem>
  );
};

export default AdminLeftNavRolesMenuButton;
