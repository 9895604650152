import Text from "@ui/text";
import useLanguage from "@src/hooks/useLanguage";
import { Button } from "@src/ui/button";
import { useTranslation } from "@src/utils/translationSets";
import gql from "graphql-tag";
import React, { useState } from "react";
import CircleQuestionOutlineIcon from "@src/ui/icons/18px/circle-question-outline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import { SubmitFeedbackPopover } from "./SubmitFeedbackPopover";

const SUPPORT_ENGLISH = "Support";
const GO_TO_HELP_CENTER_ENGLISH = "Go to Help Center";
const JOIN_OUR_COMMUNITY_ENGLISH = "Join our community";
const READ_OUR_BLOG_ENGLISH = "Read our blog";
const VIEW_ROADMAP_ENGLISH = "View roadmap";
const READ_TERMS_OF_SERVICE_ENGLISH = "Read terms of service";

export const HelpButton: React.FC = () => {
  const language = useLanguage();
  const [open, setOpen] = useState(false);
  const helpTranslation = useTranslation(SUPPORT_ENGLISH, language || "en");
  const goToHelpCenterTranslation = useTranslation(
    GO_TO_HELP_CENTER_ENGLISH,
    language || "en",
  );
  const joinOurCommunityTranslation = useTranslation(
    JOIN_OUR_COMMUNITY_ENGLISH,
    language || "en",
  );
  const readOurBlogTranslation = useTranslation(
    READ_OUR_BLOG_ENGLISH,
    language || "en",
  );
  const viewRoadmapTranslation = useTranslation(
    VIEW_ROADMAP_ENGLISH,
    language || "en",
  );
  const readTermsOfServiceTranslation = useTranslation(
    READ_TERMS_OF_SERVICE_ENGLISH,
    language || "en",
  );
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="gap-2">
          <CircleQuestionOutlineIcon />
          <Text type="P2" fontWeight="SemiBold">
            {helpTranslation.text || SUPPORT_ENGLISH}
          </Text>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuItem asChild>
          <a href="https://help.opus.so/en/" target="_blank" rel="noreferrer">
            {goToHelpCenterTranslation.text || GO_TO_HELP_CENTER_ENGLISH}
          </a>
        </DropdownMenuItem>
        <SubmitFeedbackPopover />
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <a
            href="https://www.opus.so/blog?category=Webinars"
            target="_blank"
            rel="noreferrer"
          >
            {joinOurCommunityTranslation.text || JOIN_OUR_COMMUNITY_ENGLISH}
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <a
            href="https://opustraining.notion.site/opustraining/5c7e7b1164bb44e58f1f5afdeb74bbea?v=fe8fa7f83a5a499493c91f5d6140735f"
            target="_blank"
            rel="noreferrer"
          >
            {viewRoadmapTranslation.text || VIEW_ROADMAP_ENGLISH}
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem asChild>
          <a href="https://www.opus.so/blog" target="_blank" rel="noreferrer">
            {readOurBlogTranslation.text || READ_OUR_BLOG_ENGLISH}
          </a>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <a
            href="https://www.opus.so/legal/terms"
            target="_blank"
            rel="noreferrer"
          >
            {readTermsOfServiceTranslation.text ||
              READ_TERMS_OF_SERVICE_ENGLISH}
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default HelpButton;

gql`
  mutation createDashboardFeedback($comment: String!) {
    createDashboardFeedback(input: { comment: $comment }) {
      success
    }
  }
`;
