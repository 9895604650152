import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenuContentProps,
  DropdownMenuProps,
  DropdownMenuTriggerProps,
} from "@radix-ui/react-dropdown-menu";
import { styled } from "@stitches/react";
import { css, StyleSheet } from "aphrodite";
import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { shadows } from "../styles/shadows";

type ContentProps = {
  backgroundColor?: DeprecatedColor | DeprecateTone;
};

function DropdownMenu({
  children,
  ...restProps
}: DropdownMenuProps): JSX.Element {
  return (
    <RadixDropdownMenu.Root {...restProps}>{children}</RadixDropdownMenu.Root>
  );
}

DropdownMenu.Trigger = function DropdownMenuTrigger({
  children,
  style,
  ...restProps
}: DropdownMenuTriggerProps) {
  return (
    <RadixDropdownMenu.Trigger
      style={{
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        ...style,
      }}
      {...restProps}
    >
      {children}
    </RadixDropdownMenu.Trigger>
  );
};

DropdownMenu.Content = React.forwardRef<
  HTMLDivElement,
  DropdownMenuContentProps & ContentProps
>(
  (
    {
      children,
      style,
      backgroundColor = deprecatedTones.white,
      ...restProps
    }: DropdownMenuContentProps & ContentProps,
    ref,
  ) => {
    return (
      <AnimatePresence>
        <RadixDropdownMenu.Content
          ref={ref}
          asChild
          sideOffset={6}
          align="start"
          style={{
            borderRadius: 16,
            boxShadow: shadows.dropdownShadow,
            border: "none",
            display: "block",
            margin: "0",
            outline: "none",
            textOverflow: "ellipsis",
            fontWeight: 400,
            zIndex: 1000,
            userSelect: "none",
            backgroundColor,
            ...style,
          }}
          {...restProps}
        >
          <motion.div
            initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
            animate={{
              scaleY: 1,
              opacity: 1,
              transition: { type: "spring", duration: 0.2 },
            }}
            style={{ originY: 0, originX: 1 }}
            className={css(styles.dropdownContentMotion)}
          >
            {children}
          </motion.div>
        </RadixDropdownMenu.Content>
      </AnimatePresence>
    );
  },
);

DropdownMenu.SubTrigger = styled(RadixDropdownMenu.SubTrigger, {
  borderRadius: 64,
  "&[data-highlighted]": {
    backgroundColor: deprecatedTones.gray1,
    outline: "none",
  },
});

DropdownMenu.Item = styled(RadixDropdownMenu.Item, {
  borderRadius: 64,
  "&[data-highlighted]": {
    backgroundColor: deprecatedTones.gray1,
    outline: "none",
  },
});

DropdownMenu.SubContent = styled(RadixDropdownMenu.SubContent, {
  backgroundColor: deprecatedTones.white,
  borderRadius: 16,
  padding: 4,
  boxShadow: shadows.dropdownShadow,
});

DropdownMenu.Arrow = RadixDropdownMenu.Arrow;
DropdownMenu.Portal = RadixDropdownMenu.Portal;
DropdownMenu.Separator = RadixDropdownMenu.Separator;
DropdownMenu.Sub = RadixDropdownMenu.Sub;
DropdownMenu.Group = RadixDropdownMenu.Group;
DropdownMenu.Label = RadixDropdownMenu.Label;
DropdownMenu.CheckboxItem = RadixDropdownMenu.CheckboxItem;
DropdownMenu.RadioGroup = RadixDropdownMenu.RadioGroup;
DropdownMenu.RadioItem = RadixDropdownMenu.RadioItem;
DropdownMenu.ItemIndicator = RadixDropdownMenu.ItemIndicator;

const styles = StyleSheet.create({
  dropdownContentMotion: {
    display: "flex",
    flexDirection: "column",
  },
  dropdownContainer: {
    borderRadius: 16,
    boxShadow: shadows.dropdownShadow,
    border: "none",
    display: "block",
    margin: "0",
    outline: "none",
    textOverflow: "ellipsis",
    fontWeight: 400,
  },
});

export default DropdownMenu;
