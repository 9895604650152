import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { TrainingResourceFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiGenerateUploadTrainingResourceLibraryItemsQueryVariables = Types.Exact<{
  input: Types.AdminLibraryInput;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type AiGenerateUploadTrainingResourceLibraryItemsQuery = { __typename?: 'Query', LibraryItems: { __typename?: 'LibraryItems', totalCount: number, objects: Array<{ __typename?: 'LibraryItem', id: string, trainingResource?: { __typename?: 'TrainingResource', id: string, resourceId: string, media: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null }, libraryItem: { __typename?: 'LibraryItem', id: string, lastEditedAt?: string | null, openedAt?: string | null, archivedAt?: string | null } } | null }> } };

export type TrainingResourceLibraryItemFragment = { __typename?: 'LibraryItem', id: string, trainingResource?: { __typename?: 'TrainingResource', id: string, resourceId: string, media: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null }, libraryItem: { __typename?: 'LibraryItem', id: string, lastEditedAt?: string | null, openedAt?: string | null, archivedAt?: string | null } } | null };

export const TrainingResourceLibraryItemFragmentDoc = gql`
    fragment TrainingResourceLibraryItem on LibraryItem {
  id
  trainingResource {
    ...TrainingResource
  }
}
    ${TrainingResourceFragmentDoc}`;
export const AiGenerateUploadTrainingResourceLibraryItemsDocument = gql`
    query AIGenerateUploadTrainingResourceLibraryItems($input: AdminLibraryInput!, $pagination: PaginationInput) {
  LibraryItems: AdminLibrary(input: $input, pagination: $pagination) {
    totalCount
    objects {
      ...TrainingResourceLibraryItem
    }
  }
}
    ${TrainingResourceLibraryItemFragmentDoc}`;

/**
 * __useAiGenerateUploadTrainingResourceLibraryItemsQuery__
 *
 * To run a query within a React component, call `useAiGenerateUploadTrainingResourceLibraryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiGenerateUploadTrainingResourceLibraryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiGenerateUploadTrainingResourceLibraryItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAiGenerateUploadTrainingResourceLibraryItemsQuery(baseOptions: Apollo.QueryHookOptions<AiGenerateUploadTrainingResourceLibraryItemsQuery, AiGenerateUploadTrainingResourceLibraryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiGenerateUploadTrainingResourceLibraryItemsQuery, AiGenerateUploadTrainingResourceLibraryItemsQueryVariables>(AiGenerateUploadTrainingResourceLibraryItemsDocument, options);
      }
export function useAiGenerateUploadTrainingResourceLibraryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiGenerateUploadTrainingResourceLibraryItemsQuery, AiGenerateUploadTrainingResourceLibraryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiGenerateUploadTrainingResourceLibraryItemsQuery, AiGenerateUploadTrainingResourceLibraryItemsQueryVariables>(AiGenerateUploadTrainingResourceLibraryItemsDocument, options);
        }
export type AiGenerateUploadTrainingResourceLibraryItemsQueryHookResult = ReturnType<typeof useAiGenerateUploadTrainingResourceLibraryItemsQuery>;
export type AiGenerateUploadTrainingResourceLibraryItemsLazyQueryHookResult = ReturnType<typeof useAiGenerateUploadTrainingResourceLibraryItemsLazyQuery>;
export type AiGenerateUploadTrainingResourceLibraryItemsQueryResult = Apollo.QueryResult<AiGenerateUploadTrainingResourceLibraryItemsQuery, AiGenerateUploadTrainingResourceLibraryItemsQueryVariables>;