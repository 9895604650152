import React from "react";
import Link from "next/link";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarGroupLabelIcon,
  useSidebar,
} from "@src/ui/sidebar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@src/ui/collapsible";
import UsersOutlineIcon from "@src/ui/icons/18px/users-outline";
import { useRouter } from "next/router";
import { useBooleanLocalStorage } from "@src/hooks/useLocalStorage";

import AdminLeftNavLocationsMenuButton from "@src/components/layout/left-nav/admin/AdminLeftNavLocationsMenuButton";
import AdminLeftNavRolesMenuButton from "@src/components/layout/left-nav/admin/AdminLeftNavRolesMenuButton";

const AdminLeftNavPeopleMenu: React.FC = () => {
  const { state } = useSidebar();
  const { pathname } = useRouter();
  const [isOpen, setIsOpen] = useBooleanLocalStorage(
    `admin-left-nav-people-menu-open`,
    true,
  );
  return (
    <Collapsible
      asChild
      open={state === "collapsed" ? true : isOpen}
      onOpenChange={setIsOpen}
      className="group/collapsible"
    >
      <SidebarGroup>
        <SidebarGroupLabel asChild>
          <CollapsibleTrigger>
            People
            <SidebarGroupLabelIcon />
          </CollapsibleTrigger>
        </SidebarGroupLabel>
        <CollapsibleContent>
          <SidebarGroupContent>
            <SidebarMenu>
              <AdminLeftNavLocationsMenuButton />
              <AdminLeftNavRolesMenuButton />
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  tooltip="Users"
                  isActive={pathname === "/users"}
                >
                  <Link href="/users">
                    <UsersOutlineIcon />
                    Users
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </CollapsibleContent>
      </SidebarGroup>
    </Collapsible>
  );
};

export default AdminLeftNavPeopleMenu;
