import { useEffect, useCallback, RefObject } from "react";

const useMouseDownOutside = (
  ref: RefObject<HTMLDivElement>,
  callback: () => void,
  capture?: boolean,
): void => {
  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
        e.stopPropagation();
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown, { capture });

    return () => {
      document.removeEventListener("mousedown", handleMouseDown, { capture });
    };
  }, [handleMouseDown, capture]);
};

export default useMouseDownOutside;
