import React from "react";
import { useNotificationBody } from "@components/layout/hooks/useNotificationBody";
import NotificationDot from "@src/deprecatedDesignSystem/components/NotificationDot";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import Text from "@ui/text";
import useIntersectionObserver from "@hooks/useIntersectionObserver";
import { NotificationFragment } from "@src/fragments.generated";
import { formatRelativeDate } from "@utils/dates";
import { parseISO } from "date-fns";
import { DropdownMenuItem } from "@src/ui/dropdown-menu";
import Link from "next/link";

type Props = {
  notification: NotificationFragment;
  listRef: React.RefObject<HTMLElement>;
  inViewport: (id: string) => void;
};

const NotificationRow: React.FC<Props> = (props) => {
  const rowRef = React.useRef<HTMLDivElement | null>(null);
  const { isInViewport } = useIntersectionObserver({
    root: props.listRef.current,
    target: rowRef,
    threshold: 1.0,
  });
  const [markedAsRead, setMarkedAsRead] = React.useState(false);
  const notificationBody = useNotificationBody(props.notification);
  const { title, body, url, creator } = notificationBody || {};
  React.useEffect(() => {
    if (
      isInViewport &&
      props.listRef.current &&
      !props.notification.readAt &&
      !markedAsRead
    ) {
      props.inViewport(props.notification.id);
      setMarkedAsRead(true);
    }
  }, [
    isInViewport,
    props.notification.readAt,
    props.notification.id,
    props.inViewport,
    props.listRef,
    markedAsRead,
    props,
  ]);
  if (!notificationBody) return null;
  const Component = (
    <DropdownMenuItem ref={rowRef} className="flex items-stretch">
      {creator && <PersonAvatar size="40px" person={creator} />}
      <div className="flex flex-1 flex-col items-start">
        <Text type="P2" multiline>
          {title}
        </Text>
        <Text type="P2" className="mb-1">
          {body}
        </Text>
        <Text type="P3" className="text-muted-foreground">
          {formatRelativeDate(parseISO(props.notification.createdAt))}
        </Text>
      </div>
      {!props.notification.readAt && (
        <div className="flex w-3 items-center justify-end">
          <NotificationDot size={12} />
        </div>
      )}
    </DropdownMenuItem>
  );
  if (url) {
    return (
      <Link href={url} className="w-full">
        {Component}
      </Link>
    );
  }
  return Component;
};

export default NotificationRow;
