import NotificationRow from "@src/deprecatedDesignSystem/components/NotificationRow";
import { NotificationFragment } from "@src/fragments.generated";
import React from "react";
import NoNotificationsImage from "./NoNotificationsImage";
import Text from "@ui/text";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";

type Props = {
  notifications: NotificationFragment[];
  loading: boolean;
  hasMore: boolean;
  fetchMoreNotifications: () => void;
  onNotificationRead: (id: string) => void;
};

export const NotificationList: React.FC<Props> = (props) => {
  const [fetchedMore, setFetchedMore] = React.useState(false);
  const listRef = React.useRef<HTMLDivElement | null>(null);
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const scrolledPastThreshold =
      target.scrollTop > (target.scrollHeight - target.clientHeight) * 0.7;
    if (scrolledPastThreshold && !fetchedMore) {
      props.fetchMoreNotifications();
      setFetchedMore(true);
    }
  };
  React.useEffect(() => {
    if (fetchedMore) {
      setFetchedMore(false);
    }
  }, [fetchedMore, props.notifications]);
  return (
    <>
      {props.loading && (
        <div className="flex size-full items-center justify-center">
          <Spinner size={64} color="blue-500" />
        </div>
      )}
      {props.notifications.length === 0 ? (
        <div className="flex size-full flex-col items-center justify-center">
          <NoNotificationsImage size={200} />
          <Text type="P1">You have no notifications</Text>
        </div>
      ) : (
        <div className="flex size-full flex-col">
          <Text type="H4" fontWeight="SemiBold" className="mb-4 mt-2 px-2">
            Notifications
          </Text>
          <div
            ref={listRef}
            onScroll={handleScroll}
            className="flex flex-1 flex-col overflow-y-scroll"
          >
            {props.notifications.map((n) => (
              <NotificationRow
                key={n.id}
                listRef={listRef}
                notification={n}
                inViewport={props.onNotificationRead}
              />
            ))}
            {props.hasMore && (
              <div className="flex w-full items-center justify-center py-3">
                <Spinner size={24} color="blue-500" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationList;
