import ScrollableFlexGrow from "@components/ui/flexBox/ScrollableFlexGrow";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useScrollTop from "@hooks/useScrollTop";
import { StyleSheet } from "aphrodite";
import { FC, ReactNode, useMemo } from "react";
import React from "react";
import { ActionPermissionSet, UserType } from "@src/types.generated";
import useUser from "@src/hooks/useUser";
import NotFound from "@src/pages/404";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { SidebarProvider } from "@src/ui/sidebar";
import LeftNav from "@src/components/layout/left-nav/LeftNav";
import TopNav from "@src/components/layout/top-nav/TopNav";

export type ActionPermissionString = keyof Omit<
  ActionPermissionSet,
  "__typename" | "id"
>;
type Props = {
  children: ReactNode;
  showTopNav?: boolean;
  showLeftNav?: boolean;
  overflow?: React.CSSProperties["overflow"];
  dataTestId?: string;
  forbiddenUserTypes: UserType[];
  requiredActionPermissions: ActionPermissionString[];
};

export const Page: FC<Props> = ({
  children,
  showTopNav = true,
  showLeftNav = true,
  overflow = "auto",
  dataTestId,
  forbiddenUserTypes,
  requiredActionPermissions,
}: Props) => {
  const { scrollProps } = useScrollTop();
  const LeftNavComponent = useMemo(() => {
    if (!showLeftNav) return null;
    return <LeftNav />;
  }, [showLeftNav]);
  const { user } = useUser();
  const { userActionPermissions, loading } = useActionPermissions();
  const hasPermission = useMemo(() => {
    if (loading || !user?.userType) {
      // avoid a 404 flash
      return true;
    }
    if (user?.userType && forbiddenUserTypes.includes(user.userType)) {
      return false;
    }
    if (userActionPermissions) {
      return requiredActionPermissions.every((ap) => userActionPermissions[ap]);
    }
    return false;
  }, [
    userActionPermissions,
    forbiddenUserTypes,
    requiredActionPermissions,
    loading,
    user?.userType,
  ]);
  if (!hasPermission) {
    return <NotFound />;
  }

  return (
    <SidebarProvider>
      <AutoLayout
        direction="vertical"
        styleDeclaration={styles.container}
        style={{ overflow }}
        data-testid={dataTestId}
      >
        <AutoLayout alignSelf={"stretch"} flex={1}>
          {LeftNavComponent}
          <AutoLayout direction="vertical" flex={1} alignSelf={"stretch"}>
            {showTopNav ? <TopNav /> : null}
            <ScrollableFlexGrow
              flex={1}
              alignSelf={"stretch"}
              innerStyle={{
                alignItems: "center",
              }}
              onScroll={scrollProps.onScroll}
            >
              <AutoLayout
                id={"page-content-inner"}
                {...scrollProps}
                style={{ overflow }}
                alignSelf={"stretch"}
                flex={1}
              >
                {children}
              </AutoLayout>
            </ScrollableFlexGrow>
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
    </SidebarProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
  },
});

export default Page;
