import * as React from "react";
import { IconProps } from "../types";

const PinsOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.25 7.42506C12.25 9.80706 9.13601 13.6331 7.70501 15.2651C7.33001 15.6921 6.67101 15.6921 6.29601 15.2651C4.86501 13.6321 1.75101 9.80706 1.75101 7.42506C1.75101 4.25406 4.46401 2.41406 7.00101 2.41406C9.53801 2.41406 12.251 4.25406 12.251 7.42506H12.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.5C7.9665 9.5 8.75 8.7165 8.75 7.75C8.75 6.7835 7.9665 6 7 6C6.0335 6 5.25 6.7835 5.25 7.75C5.25 8.7165 6.0335 9.5 7 9.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.228 2.88599C14.907 3.62199 16.25 5.19399 16.25 7.42499C16.25 9.80699 13.136 13.633 11.705 15.265C11.518 15.479 11.259 15.586 11 15.586"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default PinsOutlineIcon;
