import React from "react";
import Link from "next/link";
import Text from "@ui/text";
import TriangleWarningOutlineIcon from "@src/ui/icons/18px/triangle-warning-outline";
import useUser from "@src/hooks/useUser";

export const AddPhoneNumberBanner: React.FC = () => {
  const { user } = useUser();
  if (user?.phoneNumber) return null;
  return (
    <div className="mt-3 flex flex-1">
      <Link
        href={{
          pathname: "/users/[id]",
          query: { id: (user?.id || "").toString() },
        }}
        className="flex flex-1 items-center justify-center gap-2 self-stretch rounded-lg bg-purple-2 p-3"
      >
        <TriangleWarningOutlineIcon className="text-purple-12" />
        <Text type="P2" fontWeight="Medium" className="text-purple-12">
          <span className="underline">Add a phone number</span> to your account
          to sign into the mobile app
        </Text>
      </Link>
    </div>
  );
};
