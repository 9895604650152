import React, { CSSProperties, FC } from "react";
import LoadingError from "../ui/LoadingError";
import { ApolloError } from "@apollo/client";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { css, StyleSheet } from "aphrodite";

type Props = {
  loading: boolean;
  error?: ApolloError;
  loadingState?: JSX.Element;
  errorState?: JSX.Element;
  loadingContainerStyle?: CSSProperties;
  loaderVisibilityDelayMs?: number;
};

const QueryStatus: FC<Props> = ({
  loading,
  error,
  loadingState,
  errorState,
  loadingContainerStyle,
  loaderVisibilityDelayMs = 300,
}) => {
  if (error) {
    return (
      <div className={css(styles.error)}>
        {errorState || <LoadingError error={error} />}
      </div>
    );
  } else if (loading) {
    return (
      <div style={loadingContainerStyle} className={css(styles.loading)}>
        {loadingState || (
          <Spinner size={60} visibilityDelayMs={loaderVisibilityDelayMs} />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default QueryStatus;

const styles = StyleSheet.create({
  error: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});
