import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateStaffActionPermissionsMutationVariables = Types.Exact<{
  input: Types.UserActionPermissionsInput;
}>;


export type UpdateStaffActionPermissionsMutation = { __typename?: 'Mutation', updateUserActionPermissions: { __typename?: 'BaseMutationResult', success: boolean } };


export const UpdateStaffActionPermissionsDocument = gql`
    mutation updateStaffActionPermissions($input: UserActionPermissionsInput!) {
  updateUserActionPermissions(input: $input) {
    success
  }
}
    `;
export type UpdateStaffActionPermissionsMutationFn = Apollo.MutationFunction<UpdateStaffActionPermissionsMutation, UpdateStaffActionPermissionsMutationVariables>;

/**
 * __useUpdateStaffActionPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateStaffActionPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffActionPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffActionPermissionsMutation, { data, loading, error }] = useUpdateStaffActionPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffActionPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffActionPermissionsMutation, UpdateStaffActionPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffActionPermissionsMutation, UpdateStaffActionPermissionsMutationVariables>(UpdateStaffActionPermissionsDocument, options);
      }
export type UpdateStaffActionPermissionsMutationHookResult = ReturnType<typeof useUpdateStaffActionPermissionsMutation>;
export type UpdateStaffActionPermissionsMutationResult = Apollo.MutationResult<UpdateStaffActionPermissionsMutation>;
export type UpdateStaffActionPermissionsMutationOptions = Apollo.BaseMutationOptions<UpdateStaffActionPermissionsMutation, UpdateStaffActionPermissionsMutationVariables>;