import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { OrgFormStateFragmentDoc } from './useOrgFormState.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgForUpdateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgForUpdateQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', org?: { __typename?: 'Org', id: number, name: string, deactivatedAt?: string | null, startedAt?: string | null, seatsExpected?: number | null, timezone: Types.Timezone } | null } } | null };

export type UpdateOrgMutationVariables = Types.Exact<{
  orgId: Types.Scalars['Int'];
  input: Types.OrgInput;
}>;


export type UpdateOrgMutation = { __typename?: 'Mutation', updateOrg: { __typename?: 'OrgMutationResult', success: boolean, org?: { __typename?: 'Org', id: number, name: string, seatsExpected?: number | null, deactivatedAt?: string | null, startedAt?: string | null, timezone: Types.Timezone } | null } };


export const OrgForUpdateDocument = gql`
    query OrgForUpdate {
  Me {
    user {
      org {
        id
        ...OrgFormState
      }
    }
  }
}
    ${OrgFormStateFragmentDoc}`;

/**
 * __useOrgForUpdateQuery__
 *
 * To run a query within a React component, call `useOrgForUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgForUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgForUpdateQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgForUpdateQuery(baseOptions?: Apollo.QueryHookOptions<OrgForUpdateQuery, OrgForUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgForUpdateQuery, OrgForUpdateQueryVariables>(OrgForUpdateDocument, options);
      }
export function useOrgForUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgForUpdateQuery, OrgForUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgForUpdateQuery, OrgForUpdateQueryVariables>(OrgForUpdateDocument, options);
        }
export type OrgForUpdateQueryHookResult = ReturnType<typeof useOrgForUpdateQuery>;
export type OrgForUpdateLazyQueryHookResult = ReturnType<typeof useOrgForUpdateLazyQuery>;
export type OrgForUpdateQueryResult = Apollo.QueryResult<OrgForUpdateQuery, OrgForUpdateQueryVariables>;
export const UpdateOrgDocument = gql`
    mutation UpdateOrg($orgId: Int!, $input: OrgInput!) {
  updateOrg(orgId: $orgId, input: $input) {
    success
    org {
      id
      name
      seatsExpected
      deactivatedAt
      startedAt
      timezone
    }
  }
}
    `;
export type UpdateOrgMutationFn = Apollo.MutationFunction<UpdateOrgMutation, UpdateOrgMutationVariables>;

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgMutation, UpdateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(UpdateOrgDocument, options);
      }
export type UpdateOrgMutationHookResult = ReturnType<typeof useUpdateOrgMutation>;
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>;
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<UpdateOrgMutation, UpdateOrgMutationVariables>;