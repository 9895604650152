import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { NotificationFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', AdminInProductNotifications: Array<{ __typename?: 'InProductNotification', id: string, readAt?: string | null, type: Types.NotificationType, createdAt: string, message?: string | null, comment?: { __typename?: 'Comment', id: string, text: string, createdBy: { __typename?: 'Employee', id: number, name: string }, thread: { __typename?: 'CommentThread', id: string, prompt: { __typename?: 'Prompt', uuid: string } } } | null, course?: { __typename?: 'Course', id: number, draftVersion: { __typename?: 'CourseVersion', id: number }, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } | null, path?: { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } | null }> };

export type GetNotificationsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', UnreadCount: number, Notifications: { __typename?: 'InProductNotifications', totalCount: number, objects: Array<{ __typename?: 'InProductNotification', id: string, readAt?: string | null, type: Types.NotificationType, createdAt: string, message?: string | null, comment?: { __typename?: 'Comment', id: string, text: string, createdBy: { __typename?: 'Employee', id: number, name: string }, thread: { __typename?: 'CommentThread', id: string, prompt: { __typename?: 'Prompt', uuid: string } } } | null, course?: { __typename?: 'Course', id: number, draftVersion: { __typename?: 'CourseVersion', id: number }, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } | null, path?: { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, libraryItem: { __typename?: 'LibraryItem', id: string, name: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } } } | null }> } };

export type MarkNotificationsReadMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type MarkNotificationsReadMutation = { __typename?: 'Mutation', markInProductNotificationsAsRead: { __typename?: 'InProductNotificationsMutationResult', success: boolean } };


export const NotificationsDocument = gql`
    query Notifications {
  AdminInProductNotifications {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($pagination: PaginationInput) {
  Notifications: InProductNotifications(input: {}, pagination: $pagination) {
    totalCount
    objects {
      ...Notification
    }
  }
  UnreadCount: InProductNotificationUnreadCount
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const MarkNotificationsReadDocument = gql`
    mutation MarkNotificationsRead($ids: [String!]!) {
  markInProductNotificationsAsRead(inProductNotificationIds: $ids) {
    success
  }
}
    `;
export type MarkNotificationsReadMutationFn = Apollo.MutationFunction<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>(MarkNotificationsReadDocument, options);
      }
export type MarkNotificationsReadMutationHookResult = ReturnType<typeof useMarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationResult = Apollo.MutationResult<MarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;