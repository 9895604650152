import * as React from "react";
import { IconProps } from "../types";

const BoltOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M8.59501 17.096L14.239 8.52501C14.458 8.19301 14.219 7.75001 13.821 7.75001H8.99901L9.86101 1.07301C9.89501 0.810007 9.55001 0.682007 9.40401 0.904007L3.76001 9.47501C3.54101 9.80701 3.78001 10.25 4.17801 10.25H9.00001L8.13801 16.927C8.10401 17.19 8.44901 17.318 8.59501 17.096Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default BoltOutlineIcon;
