import React from "react";
import Link, { LinkProps } from "next/link";
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
  useSidebar,
} from "@src/ui/sidebar";
import { useRouter } from "next/router";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@src/ui/collapsible";
import { LocationGroupType } from "@src/types.generated";
import PinsOutlineIcon from "@src/ui/icons/18px/pins-outline";
import {
  TooltipMenu,
  TooltipMenuContent,
  TooltipMenuItem,
  TooltipMenuTrigger,
} from "@src/ui/tooltip-menu";
import useHiddenLocationGroupTypes from "@src/components/home/hooks/useHiddenLocationGroupTypes";
import _ from "lodash";

type LocationRoute = {
  label: LocationGroupType | "Locations";
  href: LinkProps["href"];
};

const LOCATIONS_ROUTES: LocationRoute[] = [
  { label: LocationGroupType.Franchise, href: "/locations/franchises" },
  { label: LocationGroupType.Brand, href: "/locations/brands" },
  { label: LocationGroupType.Region, href: "/locations/regions" },
];

const AdminLeftNavLocationsMenuButton: React.FC = () => {
  const { pathname } = useRouter();
  const { state } = useSidebar();
  const hiddenLocationGroupItems = useHiddenLocationGroupTypes();
  const visibleLocationRoutes = React.useMemo(() => {
    return LOCATIONS_ROUTES.filter((route) => {
      return !hiddenLocationGroupItems.includes(
        route.label as LocationGroupType,
      );
    });
  }, [hiddenLocationGroupItems]);
  const LocationsMenuButton = React.useMemo(
    () => (
      <SidebarMenuButton
        asChild
        tooltip="Locations"
        isActive={pathname === "/locations"}
      >
        <Link href="/locations">
          <PinsOutlineIcon />
          Locations
        </Link>
      </SidebarMenuButton>
    ),
    [pathname],
  );
  return (
    <SidebarMenuItem>
      {state === "collapsed" ? (
        <TooltipMenu>
          <TooltipMenuTrigger asChild>{LocationsMenuButton}</TooltipMenuTrigger>
          <TooltipMenuContent align="center" side="right">
            <TooltipMenuItem asChild>
              <Link href="/locations">Locations</Link>
            </TooltipMenuItem>
            {visibleLocationRoutes.map((route) => (
              <TooltipMenuItem key={route.label} asChild>
                <Link href={route.href}>{`${_.capitalize(route.label)}s`}</Link>
              </TooltipMenuItem>
            ))}
          </TooltipMenuContent>
        </TooltipMenu>
      ) : (
        <Collapsible
          className="group/collapsible"
          open={pathname.startsWith("/locations")}
        >
          <CollapsibleTrigger asChild>{LocationsMenuButton}</CollapsibleTrigger>
          <CollapsibleContent>
            {visibleLocationRoutes.map((route) => (
              <SidebarMenuSub key={route.label}>
                <SidebarMenuSubItem
                  asChild
                  isActive={pathname.includes(route.href as string)}
                  dataTestId={`${route.label}-button`}
                >
                  <Link
                    href={route.href}
                  >{`${_.capitalize(route.label)}s`}</Link>
                </SidebarMenuSubItem>
              </SidebarMenuSub>
            ))}
          </CollapsibleContent>
        </Collapsible>
      )}
    </SidebarMenuItem>
  );
};

export default AdminLeftNavLocationsMenuButton;
