import AdminTools from "@src/components/layout/top-nav/AdminTools";
import HelpButton from "@src/components/layout/top-nav/HelpButton";
import { NotificationBell } from "@src/components/layout/top-nav/NotificationBell";
import SettingsButton from "@src/components/layout/top-nav/SettingsButton";
import React from "react";
import useUser from "@hooks/useUser";
import { UserType } from "@src/types.generated";
import JumpToBar from "@src/components/layout/top-nav/JumpToBar";
import { AddPhoneNumberBanner } from "@src/components/layout/AddPhoneNumberBanner";
import { AddPaymentMethodBanner } from "@src/components/layout/AddPaymentMethodBanner";

const TopNav: React.FC = () => {
  const { user } = useUser();
  const isTrainee = !user || user?.userType === UserType.Employee;
  return (
    <div className="flex flex-col self-stretch border-b border-input p-3">
      <div className="flex items-center justify-between self-stretch">
        {!isTrainee ? <JumpToBar /> : <div className="flex-1" />}
        <div className="flex shrink-0 items-center gap-2.5">
          <AdminTools />
          {!isTrainee ? <NotificationBell /> : null}
          <HelpButton />
          <SettingsButton />
        </div>
      </div>
      <div className="flex gap-2">
        <AddPhoneNumberBanner />
        <AddPaymentMethodBanner />
      </div>
    </div>
  );
};

export default TopNav;
