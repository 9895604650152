import React from "react";

import CircleUserOutlineIcon from "@src/ui/icons/18px/circle-user-outline";
import StudentIcon from "@src/ui/icons/18px/student";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@src/ui/sidebar";
import Link from "next/link";
import LeftNavOrgSwitcher from "@src/components/layout/left-nav/LeftNavOrgSwitcher";

const TraineeLeftNav: React.FC = () => {
  return (
    <Sidebar>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <LeftNavOrgSwitcher />
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <Link href="/">
                    <StudentIcon />
                    My Training
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <Link href="/my-profile">
                    <CircleUserOutlineIcon />
                    My Profile
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <div className="h-5"></div>
    </Sidebar>
  );
};

export default TraineeLeftNav;
