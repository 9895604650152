import React, { FC, useMemo } from "react";
import { css, StyleSheet } from "aphrodite";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import TextStyles from "@src/deprecatedDesignSystem/styles/TextStyles";
import { GroupType, UserType } from "@src/types.generated";
import MultiSelectMenuContent from "@components/ui/multiSelect/MultiSelectMenuContent";
import useLocations from "@src/hooks/useLocations";
import useRoles from "@src/hooks/useRoles";
import SimpleDropdown from "../libraryItemDetailPages/course/SimpleDropdown";

export type Choice = {
  value: string;
  label: string;
};

type Props = {
  type: GroupType;
  selectedGroupIds: number[];
  setSelectedGroupIds: (groupIds: number[]) => void;
  userType: UserType;
  error?: boolean;
};

const GroupMultiSelectInput: FC<Props> = ({
  type,
  setSelectedGroupIds,
  selectedGroupIds,
  userType,
  error = false,
}) => {
  const { locations, loading: loadingLocations } = useLocations();
  const { roles, loading: loadingRoles } = useRoles();
  const allChoices: Choice[] = useMemo(() => {
    if (type === GroupType.Location) {
      return locations.map((l) => ({
        label: l.name,
        value: l.id.toString(),
      }));
    } else {
      return roles.map((r) => ({
        label: r.name,
        value: r.id.toString(),
      }));
    }
  }, [locations, roles, type]);
  const selectedChoices: Choice[] = useMemo(() => {
    return allChoices.filter((c) => selectedGroupIds.includes(Number(c.value)));
  }, [selectedGroupIds, allChoices]);
  const onSelectedChoicesChange = (newChoices: Choice[]): void => {
    setSelectedGroupIds(newChoices.map((nc) => Number(nc.value)));
  };
  const inputText = useMemo(
    () => selectedChoices.map((c) => c.label).join(", "),
    [selectedChoices],
  );
  const placeholderText = useMemo(() => {
    return type === GroupType.Location
      ? "Select locations..."
      : "Select roles...";
  }, [type]);

  return (
    <SimpleDropdown
      trigger={
        userType !== UserType.Admin || type !== GroupType.Location ? (
          <div
            className={css(
              TextStyles.textInput,
              styles.inputField,
              error && styles.errorInputStyle,
            )}
          >
            {selectedChoices.length > 0 ? (
              inputText
            ) : (
              <span className={css(styles.placeholderText)}>
                {placeholderText}
              </span>
            )}
            <DeprecatedIcon
              type="chevron-down"
              iconSize={24}
              color={deprecatedTones.gray5}
              style={{ marginLeft: 2 }}
            />
          </div>
        ) : (
          <div className={css(styles.adminInput)}>
            <span className={css(styles.placeholderText)}>
              Admins manage all locations by default
            </span>
          </div>
        )
      }
      content={
        <MultiSelectMenuContent
          allChoices={allChoices}
          selectedChoices={selectedChoices}
          onSelectedChoicesChange={onSelectedChoicesChange}
          loading={loadingLocations || loadingRoles}
          maxMenuHeight={300}
          removeClearButton={true}
        />
      }
    />
  );
};

const styles = StyleSheet.create({
  selected: {
    backgroundColor: deprecatedTones.blue2,
  },
  inputField: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 4,
    height: "unset",
    cursor: "pointer",
  },
  adminInput: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    backgroundColor: deprecatedTones.gray3Alpha,
    boxSizing: "border-box",
    borderRadius: 8,
    color: deprecatedColors.onBackground,
    lineHeight: "24px",
    fontSize: 14,
    padding: 12,
    height: 40,
    paddingTop: 8,
    paddingBottom: 8,
  },
  errorInputStyle: {
    border: `1px solid ${deprecatedTones.red9}`,
    ":hover": {
      border: `1px solid ${deprecatedTones.red9}`,
    },
    ":focus": {
      border: `1px solid ${deprecatedTones.red9}`,
    },
  },
  placeholderText: {
    color: deprecatedTones.gray7,
  },
});

export default GroupMultiSelectInput;
