import * as React from "react";
import { IconProps } from "../types";

const CaretDownFillIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M13.6484 3.75H4.35144C3.52044 3.75 2.75994 4.2045 2.36844 4.938C1.97544 5.67 2.01894 6.5565 2.47944 7.248L7.12794 14.22C7.54644 14.847 8.24544 15.222 8.99994 15.222C9.75444 15.222 10.4534 14.847 10.8719 14.22L15.5204 7.248C15.9824 6.5565 16.0244 5.6715 15.6314 4.938C15.2384 4.2045 14.4779 3.75 13.6484 3.75Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CaretDownFillIcon;
