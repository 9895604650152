import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAiModuleMutationVariables = Types.Exact<{
  input: Types.AiModuleInput;
}>;


export type CreateAiModuleMutation = { __typename?: 'Mutation', createAIModule: { __typename?: 'PathMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, path?: { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, libraryItem: { __typename?: 'LibraryItem', id: string } } | null } };

export type CreateAiModuleResultFragment = { __typename?: 'Path', id: number, draftVersion: { __typename?: 'PathVersion', id: string }, libraryItem: { __typename?: 'LibraryItem', id: string } };

export const CreateAiModuleResultFragmentDoc = gql`
    fragment CreateAIModuleResult on Path {
  id
  draftVersion {
    id
  }
  libraryItem {
    id
  }
}
    `;
export const CreateAiModuleDocument = gql`
    mutation CreateAIModule($input: AIModuleInput!) {
  createAIModule(input: $input) {
    success
    error {
      code
    }
    path {
      ...CreateAIModuleResult
    }
  }
}
    ${CreateAiModuleResultFragmentDoc}`;
export type CreateAiModuleMutationFn = Apollo.MutationFunction<CreateAiModuleMutation, CreateAiModuleMutationVariables>;

/**
 * __useCreateAiModuleMutation__
 *
 * To run a mutation, you first call `useCreateAiModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiModuleMutation, { data, loading, error }] = useCreateAiModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateAiModuleMutation, CreateAiModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAiModuleMutation, CreateAiModuleMutationVariables>(CreateAiModuleDocument, options);
      }
export type CreateAiModuleMutationHookResult = ReturnType<typeof useCreateAiModuleMutation>;
export type CreateAiModuleMutationResult = Apollo.MutationResult<CreateAiModuleMutation>;
export type CreateAiModuleMutationOptions = Apollo.BaseMutationOptions<CreateAiModuleMutation, CreateAiModuleMutationVariables>;