import UserTypeSelection from "@src/components/users/modals/inviteOrEditUserModal/UserTypeSelection";
import useInviteUserMutationWrapper from "@src/components/users/modals/inviteOrEditUserModal/hooks/useInviteUserMutationWrapper";
import useUserState from "@src/components/users/modals/inviteOrEditUserModal/hooks/useUserState";
import GroupMultiSelectInput from "@components/ui/GroupMultiSelectInput";
import PhoneNumberInput from "@components/ui/PhoneNumberInput";
import Spacer from "@components/ui/Spacer";
import SingleSelectField from "@src/deprecatedDesignSystem/components/SingleSelectField";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import useActionPermissions from "@hooks/useActionPermissions";
import useIsFullAdmin from "@hooks/useIsFullAdmin";
import useOrderedLanguageChoices from "@hooks/useOrderedLanguageChoices";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import * as Modal from "@src/deprecatedDesignSystem/components/ModalComposition";
import useCanAddMoreAdmins from "@src/hooks/billing/useCanAddMoreAdmins";
import { useModal } from "@src/hooks/useModal";
import { GroupType, UserInput, UserType } from "@src/types.generated";
import { StyleSheet } from "aphrodite";
import { gql } from "@apollo/client";
import { FC, useCallback, useMemo, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import isEmail from "validator/lib/isEmail";
import UpgradePlanCTA from "./UpgradePlanCTA";
import { SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT } from "@src/components/ui/dropdowns/constants";

type Props = {
  initialLocationId?: number | null;
};

type ValidationErrors = {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  locations: boolean;
};

const InviteAdminOrManagerModal: FC<Props> = ({ initialLocationId }) => {
  const { userActionPermissions } = useActionPermissions();
  const { closeModal } = useModal();
  const canAddMoreAdmins = useCanAddMoreAdmins();
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    jobTitle,
    language,
    userType,
    roleIds,
    locationIds,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setEmail,
    setJobTitle,
    setLanguage,
    setUserType,
    setRoleIds,
    setLocationIds,
  } = useUserState(undefined, initialLocationId ? [initialLocationId] : []);

  const [errors, setErrors] = useState<ValidationErrors>({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    locations: false,
  });
  const isFullAdmin = useIsFullAdmin();
  const getValidationErrors = useCallback(() => {
    return {
      firstName: firstName.length === 0,
      lastName: lastName.length === 0,
      phoneNumber: phoneNumber
        ? !isPossiblePhoneNumber(phoneNumber || "") ||
          phoneNumber.startsWith("+1555")
        : false,
      email: !isEmail(email),
      locations: locationIds.length === 0 && userType !== UserType.Admin,
    };
  }, [email, firstName, lastName, phoneNumber, locationIds, userType]);
  const getHasValidationErrors = useCallback(
    (errors: ValidationErrors) => {
      if (userType === UserType.Employee) {
        return errors.phoneNumber || errors.firstName || errors.lastName;
      } else {
        return (
          errors.email ||
          errors.phoneNumber ||
          errors.firstName ||
          errors.lastName ||
          errors.locations
        );
      }
    },
    [userType],
  );

  const [createUserMutation, { loading: createUserLoading }] =
    useInviteUserMutationWrapper();
  const userInput: UserInput = {
    firstName,
    lastName,
    userType: userType,
    phoneNumber: phoneNumber.length > 0 ? phoneNumber : null,
    language: language,
    email: email || null,
    jobTitle: jobTitle || null,
    roleIds: roleIds,
    locationIds: locationIds,
  };
  const langChoices = useOrderedLanguageChoices();
  const locationString = useMemo(() => {
    let ret =
      userType === UserType.Employee
        ? "Where do they work?"
        : "Which locations do they manage?";

    if (userType != UserType.Admin) {
      ret = ret + "*";
    }
    return ret;
  }, [userType]);

  const addAdminsDisabled = userType === UserType.Admin && !canAddMoreAdmins;

  return (
    <Modal.Modal>
      <Modal.ModalHeader>Invite user</Modal.ModalHeader>

      <Modal.ModalBody>
        {isFullAdmin && (
          <UserTypeSelection
            userType={userType}
            setUserType={setUserType}
            disableTraineeOption
            showDescription={!addAdminsDisabled}
          />
        )}

        {addAdminsDisabled && (
          <UpgradePlanCTA description="You’ve reached the limit of admins on the Starter plan. To continue either delete an admin or upgrade your plan." />
        )}
        {!addAdminsDisabled && (
          <>
            <AutoLayout spaceBetweenItems={8} marginTop={8}>
              <TextField
                text={firstName}
                label="First Name"
                onTextChange={(val) => {
                  setErrors({ ...errors, firstName: false });
                  setFirstName(val);
                }}
                error={errors.firstName}
                required
              />
              <Spacer size={8} />
              <TextField
                text={lastName}
                label="Last Name"
                onTextChange={(val) => {
                  setErrors({ ...errors, lastName: false });
                  setLastName(val);
                }}
                error={errors.lastName}
                required
              />
            </AutoLayout>
            <PhoneNumberInput
              label="Mobile Phone Number (Highly recommended)"
              style={{ marginTop: 8 }}
              value={phoneNumber}
              onChange={(val) => {
                setErrors({ ...errors, phoneNumber: false });
                setPhoneNumber(val || "");
              }}
              error={errors.phoneNumber}
            />
            <Spacer size={8} />

            <TextField
              text={email}
              label="Email Address"
              onTextChange={(val) => {
                setErrors({ ...errors, email: false });
                setEmail(val);
              }}
              error={errors.email}
              required
            />
            <Spacer size={8} />
            <TextField
              text={jobTitle}
              label="Job Title"
              onTextChange={(val) => {
                setJobTitle(val);
              }}
            />
            <Spacer size={8} />

            {langChoices.length !== 0 && (
              <SingleSelectField
                label="Language"
                value={language}
                containerStyle={{ alignSelf: "stretch" }}
                searchEnabled={true}
                placeholder="English"
                fontWeight="Regular"
                options={langChoices}
                dropdownHeight={SINGLE_AND_MULTISELECT_DROPDOWN_HEIGHT}
                onValueChange={(val) => setLanguage(val as Language)}
              />
            )}
            <Spacer size={8} />

            <Text
              type="P3"
              fontWeight="SemiBold"
              inline
              style={{ marginBottom: 4 }}
            >
              {locationString}
            </Text>
            <GroupMultiSelectInput
              selectedGroupIds={locationIds}
              setSelectedGroupIds={(newIds) => {
                setErrors({ ...errors, locations: false });
                setLocationIds(newIds);
              }}
              type={GroupType.Location}
              userType={userType}
              error={errors.locations}
            />
            <Spacer size={8} />

            <>
              <Text
                type="P3"
                fontWeight="SemiBold"
                inline
                style={{ marginBottom: 4 }}
              >
                What is their role?
              </Text>
              <GroupMultiSelectInput
                selectedGroupIds={roleIds}
                setSelectedGroupIds={setRoleIds}
                type={GroupType.Role}
                userType={userType}
              />
            </>
          </>
        )}
      </Modal.ModalBody>
      <Modal.ModalFooter>
        <Button
          text="Cancel"
          variant="Gray"
          onClick={() => closeModal()}
          styleDeclaration={styles.modalButton}
        />
        <Button
          text="Invite"
          data-testid="invite-user-button"
          variant="Primary"
          styleDeclaration={styles.modalButton}
          loading={createUserLoading}
          disabled={addAdminsDisabled}
          onClick={() => {
            const validationErrors = getValidationErrors();
            const hasValidationErrors =
              getHasValidationErrors(validationErrors);
            setErrors(validationErrors);
            if (hasValidationErrors) {
              return;
            }
            if (userActionPermissions?.inviteOthers) {
              createUserMutation({
                variables: {
                  input: userInput,
                },
              });
            }
          }}
        />
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

export default InviteAdminOrManagerModal;

gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      success
      error {
        code
        message
      }
      user {
        id
      }
    }
  }
`;

const styles = StyleSheet.create({
  modalButton: {
    flex: 1,
  },
});
